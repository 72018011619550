import styled, { keyframes } from "styled-components";


export const ContainerAll = styled.div`
    width: 100vw;
    height: auto;
    background: linear-gradient(344deg, rgba(40,40,55,1) 0%, rgba(16,16,18,1) 50%);
    position: relative;
`
export const Degrade = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 32vh;
    background-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(58, 58, 58, 0));
    z-index: 0;
`
export const TextBig = styled.div`
    width: auto;
    height: auto;
    background-color: transparent;
    font-size: 38px;
    letter-spacing: 6px;
    text-shadow: 10px 5px 10px rgb(40, 40, 55);
    font-family: 'Lato';
    color: aliceblue;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 4%;
    white-space: nowrap;

    @media (max-width: 791px) {
      font-size: 28px;
    }
`
const shine = keyframes`
  0% {
    background-position: 0;
  }
  60% {
    background-position: 360px;
  }
  100% {
    background-position: 700px;
  }
`;

// Estilização do texto
export const MiniText = styled.div`
  width: auto;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #36364a;
  background: linear-gradient(to right, #9f9f9f 0, #fff 10%, #868686 20%);
  background-position: 0;
  background-clip: text; /* Standard syntax */
  -webkit-background-clip: text; /* Webkit compatibility */
  -webkit-text-fill-color: transparent;
  animation: ${shine} 8s infinite linear;
  animation-fill-mode: forwards;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;

  @media (max-width: 1000px) {
    width: 150%;
    white-space: normal;
    text-align: center;
  }
  @media (max-width: 791px) {
    font-size: 12px;
    bottom: -90px;
    width: 130%;
    white-space: normal;
    text-align: center;
  }

`;

export const FooterInfos = styled.div`
    width: 100vw;
    height: 100%;
    padding: 140px 18% 140px 18%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1450px) {
      padding: 140px 14% 140px 14%;
    }
    @media (max-width: 1250px) {
      padding: 140px 4% 140px 4%;
    }
    @media (max-width: 791px) {
      padding: 180px 4% 180px 4%;
    }
    @media (max-width: 450px) {
      padding: 200px 4% 180px 4%;
    }
`
export const TextSmallContainer = styled.div`
    width: 45%;
    height: auto;
    margin-bottom: 80px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: aliceblue;

    @media (max-width: 1000px) {
      width: 60%;
    }
    @media (max-width: 791px) {
       font-size: 18px;
    }
    @media (max-width: 450px) {
      width: 80%;
    }
`
export const LinksInfosQrcodesContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;

    @media (max-width: 1000px) {
      row-gap: 24px;
      flex-wrap: wrap;
    }
`
export const LinksContainer = styled.div`
    width: calc(100% / 4);
    height: auto;

    @media (max-width: 1000px) {
      width: calc(100% / 2);
    }
    @media (max-width: 450px) {
      width: 100%;
    }
`
export const InfosContainer = styled.div`
    width: calc(100% / 4);
    height: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) {
      width: calc(100% / 2);
      align-items: center;
    }
    @media (max-width: 450px) {
      width: 100%;
      align-items: center;
    }
`
export const TextInformacoesDeContato = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 8px;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: white;

    @media (max-width: 1000px) {
      width: auto;
    }

`
export const IconTextContainer = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 8px;

    @media (max-width: 1000px) {
      width: auto;
    }
`
export const IconContainer = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
`
export const IconImage = styled.img`
    width: 100%;
    height: 100%;
`
export const TextsIcon = styled.div`
    width: 100%;
    height: auto;
    text-indent: 30px;
    text-align: justify;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-weight: 300;
    color: aliceblue;

    @media (max-width: 1000px) {
      width: auto;
      text-align: center;
    }
    & .Bairro, .Cidade-Cep {
      @media (max-width: 1000px) {
        width: auto;
        text-align: center;
      }
    }
`

export const SocialMediasContainer = styled.div`
    width: calc(100% / 4);

    @media (max-width: 1000px) {
      width: calc(100% / 2);
    }
    @media (max-width: 450px) {
      width: 100%;
    }
`
export const SocialMedias = styled.div`
  ul {
    list-style: none;
  }

  .example-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 0.5rem;
    padding-left: 0;
  }

  .example-2 .icon-content {
    margin: 0 10px;
    position: relative;
    display: flex;
    align-items: center; /* Alinha o ícone e o tooltip verticalmente */
  }

  .example-2 .icon-content .tooltip {
    position: absolute;
    left: calc(100% + 10px); /* Posiciona o tooltip ao lado do ícone */
    top: 50%;
    transform: translateY(-50%); /* Centraliza verticalmente */
    color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    font-size: 14px;
    background-color: #000;
    white-space: nowrap;
    transition: all 0.3s ease;
  }

  .example-2 .icon-content:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }

  .example-2 .icon-content a {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #4d4d4d;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
  }

  .example-2 .icon-content a:hover {
    box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
    color: white;
  }

  .example-2 .icon-content a svg {
    position: relative;
    z-index: 1;
    width: 30px;
    height: 30px;
  }

  .example-2 .icon-content a .filled {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #000;
    transition: all 0.3s ease-in-out;
  }

  .example-2 .icon-content a:hover .filled {
    height: 100%;
  }

  /* Estilizando cada rede social */
  .example-2 .icon-content a[data-social="whatsapp"] .filled,
  .example-2 .icon-content a[data-social="whatsapp"] ~ .tooltip {
    background-color: #128c7e;
  }

  .example-2 .icon-content a[data-social="facebook"] .filled,
  .example-2 .icon-content a[data-social="facebook"] ~ .tooltip {
    background-color: #3b5998;
  }

  .example-2 .icon-content a[data-social="instagram"] .filled,
  .example-2 .icon-content a[data-social="instagram"] ~ .tooltip {
    background: linear-gradient(
      45deg,
      #405de6,
      #5b51db,
      #b33ab4,
      #c135b4,
      #e1306c,
      #fd1f1f
    );
  }

  .example-2 .icon-content a[data-social="youtube"] .filled,
  .example-2 .icon-content a[data-social="youtube"] ~ .tooltip {
    background-color: #ff0000;
  }
`;


export const NavContainer = styled.nav`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
`
export const UnorderedList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
`
export const ListElement = styled.li`
    width: auto;
    height: auto;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: white;
    transition: 0.3s;
    cursor: pointer;
    position: relative; /* Necessário para o posicionamento dos pseudo-elementos */
    display: flex;

    &::before,
    &::after {
        content: '';
        width: 0%;
        height: 2px;
        background: linear-gradient(to right, #a6a6a6 0%, #ffffff 100%);
        display: block;
        position: absolute;
        bottom: 0; /* Alinha as barras ao final do botão */
        transition: width 0.5s;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }

    &:hover::after,
    &:hover::before {
        width: 100%;
  }
`
export const ListElementImageContainer = styled.div`
    width: 22px;
    height: 22px;
    margin-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ListElementImage = styled.img`
    width: 100%;
    height: 100%;
`

export const LocalizacaoIcon = styled.div`
    position: absolute;
    top: 0;
    left: 0;

  .loader {
    width: 24px;
    height: 24px;
    position: relative;
    transform: rotate(45deg);
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50% 50% 0 50%;
    background: #0000;
    background-image: radial-gradient(circle 8.2px at 50% 50%,#0000 94%, #ff4747);
  }

  .loader:after {
    animation: pulse-ytk0dhmd 1s infinite;
    transform: perspective(336px) translateZ(0px);
  }

  @keyframes pulse-ytk0dhmd {
    to {
      transform: perspective(336px) translateZ(168px);
      opacity: 0;
    }
  }`;



export const QrcodesContainer = styled.div`
    width: calc(100% / 4);
    display: flex;
    justify-content: end;

    @media (max-width: 1000px) {
      width: calc(100% / 2);
    }
    @media (max-width: 450px) {
      width: 100%;
      justify-content: center;
    }
`

export const Qrcode = styled.div`
  .card-id567 {
    width: 190px;
    height: 190px;
    background: rgb(22, 22, 22);
    color: white;
    border-radius: 1rem;
    padding: 1rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms ease;
    animation: 8s thumb-thumb infinite;
  }

  .card-id567 svg path {
    transition: 300ms ease;
    opacity: 0;
  }
  .qrcode-image {
    width: 190px; /* Ajuste conforme necessário */
    height: 190px; /* Ajuste conforme necessário */
    opacity: 0;
    transition: 300ms ease;
  }

  .card-id567:hover .qrcode-image {
    opacity: 1;
  }

  .bold-567 {
    font-weight: bold;
  }

  .creator-points {
    width: 3.25rem;
    height: 3rem;
    color: rgb(167 139 250);
      /* w-5 h-5 mr-2 text-indigo-400 */
  }

  .blurry-splash {
    position: absolute;
    inset: 0;
    width: 60px;
    margin: 0 auto;
    height: 60px;
    border-radius: 1rem;
    z-index: -1;
    opacity: 70%;
    filter: blur(15px);
    background: linear-gradient(
        120deg, rgba(167, 139, 250, 0.24)
        , rgba(167, 139, 250, 0.384), rgba(167, 139, 250, 0.226));
  }

  .prompt-id567 {
    position: absolute;
    color: rgb(173, 173, 173);
    text-align: center;
  }

  .really-small-text {
    text-align: center;
    width: 100%;
    position: absolute;
    font-size: 8px;
    margin-top: 28px;
    opacity: 0.5;
  }

  .card-id567:hover {
    cursor: none;
    background-color: white;
  }

  .card-id567:hover .prompt-id567 {
    transition: 300ms ease;
    opacity: 0;
  }

  .token-container {
    animation: 2s spinny-token-yayyy infinite;
    margin-bottom: 10px;
  }

  .prompt-id567 svg path {
    stroke: none;
    opacity: 1;
  }

  .card-id567:hover svg path {
    opacity: 1;
  }

  @keyframes spinny-token-yayyy {
    0% {
      transform: perspective(200px) rotateY(0deg);
    }

    100% {
      transform: perspective(200px) rotateY(360deg);
    }
  }

  @keyframes thumb-thumb {
    0%, 10%, 100% {
      transform: scale(100%);
    }

    5% {
      transform: scale(103%);
    }

    7% {
      transform: scale(97%);
    }
  }`;
