import { 
    Container, 
    Image, 
    ImageContainer, 
    TextsContainer, 
    TitleImagageContainer, 
    ImageContent, 
    CheckIconContainer, 
    ChekIcon, TextSmall, 
    IconContainer, 
    IconImage, 
    TextSmall_2, 
    InfosContainer, 
    TextBig_2, 
    ArrowContainer, 
    ArrowImg,
    CheckTextContent,
    Container_1,
    Degrade,
    Degrade_2,
    Span,
    CardsContainer,
    Card,
    CheckTextContainer,
    CheckTextsContainer,
    REPinformacoes,
    VideoContainer,
    VideoBackground,
    DegradeVideo,
    TextsContainer_2,
    ControleDeAcessoInfos,
    ControleDeAcessoInfos_2,
    ControleDeAcessoTextsBigContainer,
    TextBig_3,
    CheckTextsContainer_2,
    REPTextBig,
    IdentificacaoInfos,
    IdentificacaoInfos_2,
    IdentificacaoTextsBigContainer,
    ProdutosContainerAll,
    SecullumWebTextsBigContainer,
    ButtonContainerSecullumWeb,
    Button,
    ButtonContainerControleDeAcesso,
    TextsContent,
    ImageBigContainer,
    ImageCDA,
    ButtonContainer,
    ImageSW,
    ImageREP,
    ImageIC,
} from "./produtos_styled";
import image1 from '../../assets/images/notbook_image.png'
import image2 from "../../assets/images/ponto-eletronico.png"
import image3 from "../../assets/images/catraca.png"
import image3_2 from "../../assets/images/controle-de-acesso-produto-800x605.png"
import image4 from "../../assets/images/identificacao-corporativa-produto-800x865.png"
import facialBiometric from "../../assets/images/facial-icons-img.png"
import rfid from "../../assets/images/rfid-icon-2.png"
import qrcode from "../../assets/images/qrcode-icon-3.png"
import arrow from "../../assets/images/seta-down.png"
import check_2 from "../../assets/images/iconscheck1.png"
import { useEffect, useState } from "react";
import Card3D from "../card_produtos/card_produtos";
import ScanningCard from "../scanningElement/scanning";
import videoDesktop from "../../assets/videos/video4.mp4";
import videoMobile from "../../assets/videos/video4_comprimido_2.mp4";
import ScrollReveal from "scrollreveal";
import { Loader } from "../../home/section1/section_1_styled";


function Produtos() {

    const [selected, setSelected] = useState('Biometria Facial');
    const [hoveredCard, setHoveredCard] = useState<number | null>(null);
    const isMobile = window.innerWidth <= 768; // Define o breakpoint para mobile
    const videoSrc = isMobile ? videoMobile : videoDesktop;
    const [isVisible, setIsVisible] = useState(false);
    const [saibaMais, setSaibaMais] = useState(true)
    const [saibaMais2, setSaibaMais2] = useState(true)
    const [saibaMais3, setSaibaMais3] = useState(true)
    const [saibaMais4, setSaibaMais4] = useState(true)

    const cards = [
        { id: 1, nome: 'Biometria Facial', class: 'Biometria Facial', image: facialBiometric},
        { id: 2, nome: '', class: 'Biometria Digital', image: <ScanningCard/>},
        { id: 3, nome: 'RFID', class: 'RFID', image: rfid},
        { id: 4, nome: 'QrCode', class: 'QrCode', image: qrcode},
    ];

    useEffect(() => {
        ScrollReveal().reveal(".image-1", {
            duration: 2000,
            origin: "right",
            distance: "145px",
            delay: 800,
            reset: false,
        });
        ScrollReveal().reveal(".image-2", {
            duration: 2000,
            origin: "left",
            distance: "145px",
            delay: 800,
            reset: false,
        });
        ScrollReveal().reveal(".image-3", {
            duration: 2000,
            origin: "right",
            distance: "145px",
            delay: 800,
            reset: false,
        });
        ScrollReveal().reveal(".image-4", {
            duration: 2000,
            origin: "left",
            distance: "145px",
            delay: 800,
            reset: false,
        });
      }, []);

    useEffect(()=> {}, [selected])
    
    useEffect(()=> {}, [saibaMais]);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
    
        const videoElement = document.getElementById("lazyVideo");
        if (videoElement) observer.observe(videoElement);
    
        return () => {
          if (videoElement) observer.unobserve(videoElement);
        };
      }, []);

    return (
        <ProdutosContainerAll>
            <Container_1 id="secullumWeb">
                <Degrade/>
                <Degrade_2/>
                <TitleImagageContainer>
                    <Card3D
                        title='Secullum Web'
                        text='A Secullum oferece soluções avançadas de controle de ponto e gestão de frequência, 
                        atendendo empresas de todos os portes com tecnologia de ponta.'
                        minitext='Software avançado para otimizar a gestão de ponto e controle de acesso.'
                    />
                    {saibaMais?  
                        <ButtonContainer>
                            <Button red>
                                <button className="btn" onClick={()=> setSaibaMais(false)}>Saiba mais</button>
                            </Button>
                        </ButtonContainer>
                        :
                        <ImageContainer>
                            <ImageContent>
                                <Image src={image1} className="image-SW" saibaMais={saibaMais}/>
                            </ImageContent>
                        </ImageContainer>
                    }

                </TitleImagageContainer>
                {saibaMais? 
                    <ImageBigContainer>
                        <ImageSW src={image1} className="image-1"/>
                    </ImageBigContainer>
                    :
                    <TextsContainer className="Texts-Container">
                        <CheckTextContainer className="Check-Text-Container">
                            <CheckTextContent className="Check-Text-Content">
                                <TextSmall>
                                    <Span>Secullum Ponto Web:</Span> Um sistema completo de controle de ponto que permite registros via aplicativo, 
                                    reconhecimento facial, QR Code e geolocalização. É ideal para empresas com colaboradores 
                                    em regimes híbridos ou remotos, garantindo acessibilidade e transparência para funcionários e RH.
                                </TextSmall>
                                <CheckIconContainer>
                                    
                                </CheckIconContainer>
                            </CheckTextContent>
                        </CheckTextContainer>

                        <CheckTextContainer>
                            <CheckTextContent>
                                <TextSmall>
                                    <Span>Integração e automação:</Span> Facilita a comunicação com folhas de pagamento e automatiza processos, 
                                    como cálculos de horas trabalhadas e gestão de banco de horas, otimizando o tempo do RH e reduzindo 
                                    custos operacionais.
                                </TextSmall>
                                <CheckIconContainer>
                                    
                                </CheckIconContainer>
                            </CheckTextContent>
                        </CheckTextContainer>

                        <CheckTextContainer>
                            <CheckTextContent>
                                <TextSmall>
                                    <Span>Acessibilidade e mobilidade:</Span> Com backup em nuvem e interface totalmente web, o sistema pode ser 
                                    acessado de qualquer lugar, incluindo funcionalidades como envio de atestados pelo app 
                                    e assinatura eletrônica do cartão de ponto.
                                </TextSmall>
                                <CheckIconContainer>
                                    
                                </CheckIconContainer>
                            </CheckTextContent>
                        </CheckTextContainer>

                        <CheckTextContainer style={{marginBottom: '40px'}}>
                            <CheckTextContent>
                                <TextSmall>
                                    <Span>Flexibilidade e customização:</Span> O sistema é adaptável a diferentes necessidades empresariais, 
                                    incluindo configurações específicas para múltiplos contratos, hierarquias e feriados regionais.
                                </TextSmall>
                                <CheckIconContainer>
                                    
                                </CheckIconContainer>
                            </CheckTextContent>
                        </CheckTextContainer>

                        <SecullumWebTextsBigContainer>
                            <TextSmall className="secullumTextSmall">
                                Essas soluções tornam o controle de jornada mais eficiente, seguro e moderno, permitindo às empresas reduzir custos e melhorar a gestão de pessoas.
                            </TextSmall>
                            <TextSmall className="secullumTextSmall">
                                Para mais detalhes, visite o site oficial da Secullum:{" "}
                                <a 
                                    href="https://www.secullum.com.br" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    style={{ color: "inherit", textDecoration: "underline" }} // Opcional: ajuste de estilo
                                >
                                    www.secullum.com.br
                                </a>.
                            </TextSmall>
                        </SecullumWebTextsBigContainer>
                    </TextsContainer>
                }
                

            </Container_1>

            <VideoContainer id="lazyVideo">
                {isVisible ? (
                    <VideoBackground controls muted src={videoSrc} />
                ) : (
                    <Loader>
                        <div className="honeycomb">
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                    </Loader>
                )}
                <DegradeVideo />
            </VideoContainer>

            <Container id="rep">
                <Degrade/>
                <Degrade_2/>
                {saibaMais2?
                    <ImageBigContainer>
                        <ImageREP src={image2} className="image-2"/>
                    </ImageBigContainer>
                    :
                    <TextsContainer_2>
                        <REPinformacoes>
                            <CardsContainer hovered className="Cards-Container">
                                {cards.map((card) => (
                                    <Card isHovered={hoveredCard === card.id || hoveredCard === null}
                                    key={card.id}
                                    onMouseEnter={() => {
                                        setHoveredCard(card.id)
                                        setSelected(card.class)
                                    }}
                                    onMouseLeave={() => {
                                        setHoveredCard(null)
                                        setSelected('Biometria Facial')
                                    }}
                                    >
                                    <IconContainer >
                                        {typeof card.image === 'string' ? (
                                            <IconImage src={card.image} alt={card.nome}/>
                                        ) : (
                                            card.image 
                                        )}   
                                    </IconContainer>
                                    <TextSmall_2>
                                        {card.nome === "Biometria Facial" && 
                                        <ArrowContainer>
                                            <ArrowImg src={arrow}/>
                                        </ArrowContainer>
                                        }
                                        {card.nome}
                                    </TextSmall_2>
                                        
                                    </Card>
                                ))}
                            </CardsContainer>


                            {selected === 'Biometria Facial' && <>
                                <InfosContainer className="Infos-Container">
                                    <TextBig_2 className="rep-text-big">
                                        Utiliza reconhecimento facial para identificar os trabalhadores com base em características únicas do rosto.
                                    </TextBig_2>
                                    <TextBig_2 className="rep-text-big">
                                        Benefícios do Uso de Biometria Facial:
                                    </TextBig_2>
                                    <CheckTextsContainer>
                                        <CheckTextContent>
                                            <TextSmall>Redução de fraudes no registro de ponto.</TextSmall>
                                            <CheckIconContainer>
                                                
                                            </CheckIconContainer>
                                        </CheckTextContent>
                                    </CheckTextsContainer>

                                    <CheckTextsContainer>
                                        <CheckTextContent>
                                            <TextSmall>Maior higiene, já que não exige contato físico com o dispositivo.</TextSmall>
                                            <CheckIconContainer>
                                                
                                            </CheckIconContainer>
                                        </CheckTextContent>
                                    </CheckTextsContainer>

                                    <CheckTextsContainer>
                                        <CheckTextContent>
                                            <TextSmall>Alta precisão e rapidez na identificação, mesmo em condições de baixa iluminação.</TextSmall>
                                            <CheckIconContainer>
                                                
                                            </CheckIconContainer>
                                        </CheckTextContent>
                                    </CheckTextsContainer>

                                    <CheckTextsContainer>
                                        <CheckTextContent>
                                            <TextSmall>Adequado para empresas que buscam tecnologias mais modernas e alinhadas às tendências de transformação digital.</TextSmall>
                                            <CheckIconContainer>
                                                
                                            </CheckIconContainer>
                                        </CheckTextContent>
                                    </CheckTextsContainer>

                                </InfosContainer>
                
                            </>
                            }

                            {selected === 'Biometria Digital' && <InfosContainer>
                                <TextBig_2>
                                    Identifica o trabalhador por meio de suas digitais.
                                    É amplamente utilizado por ser acessível e confiável em ambientes de trabalho controlados. 
                                </TextBig_2>
                                <TextBig_2>
                                    Benefícios do Uso de Biometria Digital:
                                </TextBig_2>
                                <CheckTextsContainer>
                                    <CheckTextContent>
                                        <TextSmall>Permite autenticações rápidas e sem a necessidade de memorizar senhas ou carregar cartões.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer>

                                <CheckTextsContainer>
                                    <CheckTextContent>
                                        <TextSmall>Redução de fraudes e roubos de identidade.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer>

                            </InfosContainer>
                            }

                            {selected === 'RFID' && <InfosContainer>
                                <TextBig_2>
                                    Funcionam por meio de cartões ou tags que se comunicam com o relógio por rádio frequência.
                                </TextBig_2>
                                <TextBig_2>
                                    Indicação de uso dos cartões de proximidade:
                                </TextBig_2>
                                <CheckTextsContainer>
                                    <CheckTextContent>
                                        <TextSmall>Ambientes onde há alto fluxo de trabalhadores</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer>

                                <CheckTextsContainer>
                                    <CheckTextContent>
                                        <TextSmall>Ambientes onde a biometria não se torna viável.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer>

                            </InfosContainer>
                            }
                            
                            {selected === 'QrCode' && <InfosContainer>
                                <TextBig_2>
                                    Os registros são feitos por meio de crachás ou dispositivos móveis que exibem um código de barras ou QR Code.
                                </TextBig_2>

                            </InfosContainer>
                            }
                        </REPinformacoes>

                        <REPTextBig>
                            <TextBig_2 className="REPTextBig">
                                O REP com biometria facial está se tornando uma tendência devido à sua eficiência e flexibilidade em diferentes cenários corporativos. 
                                Além de cumprir as normas legais, proporciona maior segurança e agilidade no controle de jornadas.
                            </TextBig_2>
                        </REPTextBig>

                    </TextsContainer_2>
                }

                <TitleImagageContainer invertido>
                    <Card3D
                        invertido
                        title='Relógio Eletrônico de Ponto (REP)'
                        text='O Relógio Eletrônico de Ponto (REP) é um dispositivo regulamentado pelo Ministério do Trabalho e Emprego 
                        (Portaria 1.510/2009) para registrar e controlar a jornada de trabalho de forma segura e precisa. 
                        Ele é amplamente utilizado em empresas brasileiras para garantir conformidade com as exigências legais
                        e proporcionar transparência no controle de horas trabalhadas pelos funcionários.'
                        minitext='Equipamento seguro e confiável.'
                        rep
                    />
                    {saibaMais2?  
                        <ButtonContainer>
                            <Button red>
                                <button className="btn" onClick={()=> setSaibaMais2(false)}>Saiba mais</button>
                            </Button>
                        </ButtonContainer>
                        :
                        <ImageContainer>
                        <ImageContent>
                            <Image src={image2} className="image-REP"/>
                        </ImageContent>
                    </ImageContainer>
                    }
                </TitleImagageContainer>
            </Container>


            <Container id="controleDeAcesso">
                <Degrade/>
                <Degrade_2/>
                <TitleImagageContainer>
                    <Card3D
                        title='Controle de Acesso'
                        text='Controle de Acesso refere-se a sistemas e tecnologias projetados para monitorar, gerenciar e restringir o 
                        acesso de pessoas ou veículos a locais específicos, garantindo segurança e eficiência. 
                        Esses sistemas são amplamente utilizados em empresas, condomínios, instituições e eventos.'
                        minitext='Soluções para monitorar entradas e saídas com eficiência.'
                    />
                    {saibaMais3?  
                        <ButtonContainer>
                            <Button red>
                                <button className="btn" onClick={()=> setSaibaMais3(false)}>Saiba mais</button>
                            </Button>
                        </ButtonContainer>
                        :
                        <ImageContainer>
                            <ImageContent>
                                <Image src={image3_2} className="image-CDA" saibaMais={saibaMais2}/>
                            </ImageContent>
                        </ImageContainer>
                    }
                </TitleImagageContainer>
                
                {saibaMais3?
                    <ImageBigContainer>
                        <ImageCDA src={image3} className="image-3"/>
                    </ImageBigContainer>
                    :
                    <TextsContainer>
                        <ControleDeAcessoInfos>
                            <TextsContent>
                                <TextBig_2 className="CDA">
                                    Tecnologias de Identificação:
                                </TextBig_2>
                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Biometria (impressão digital, facial ou íris): Alta segurança, elimina fraudes.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>

                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Cartões RFID: Identificação rápida e sem contato.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>

                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Códigos de Barras ou QR Codes: Simples e adaptáveis.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>

                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Senha ou PIN: Facilidade de uso em aplicações básicas.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>
                            </TextsContent>

                            <TextsContent>
                                <TextBig_2 className="CDA">
                                    Gestão Centralizada:
                                </TextBig_2>
                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Monitoramento e controle em tempo real, incluindo a emissão de alertas para entradas não autorizadas.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>

                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Registro de logs detalhados de acessos para auditorias.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>
                            </TextsContent>
                        </ControleDeAcessoInfos>

                        <ControleDeAcessoInfos_2>
                            <TextsContent>
                                <TextBig_2 className="CDA">
                                    Integração com Outros Sistemas:
                                </TextBig_2>
                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Conexão com câmeras, alarmes e ERPs corporativos para maior automação e controle unificado.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>
                            </TextsContent>

                            <TextsContent>
                                <TextBig_2 className="CDA">
                                    Automação Física:
                                </TextBig_2>
                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Catracas, portas automáticas, cancelas e portões para gerenciar o fluxo de pessoas e veículos.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>
                            </TextsContent>
                        </ControleDeAcessoInfos_2>
                            
                        <ControleDeAcessoTextsBigContainer>
                            <TextBig_3 className="CDA">
                                O Controle de Acesso é fundamental em um mundo onde segurança e eficiência são prioridades. 
                                Ele garante que somente pessoas autorizadas tenham acesso a determinados locais, 
                                fortalecendo a proteção de ativos e dados.
                            </TextBig_3>
                            <TextBig_3 className="CDA">
                                Quer saber mais sobre essa solução para sua empresa? Fale conosco!
                            </TextBig_3>
                        </ControleDeAcessoTextsBigContainer>
                    </TextsContainer>
                }

            </Container>


            <Container id="identificacaoCorporativa">
                <Degrade/>
                <Degrade_2/>
                {saibaMais4?
                    <ImageBigContainer>
                        <ImageIC src={image4} className="image-4"/>
                    </ImageBigContainer>
                    :
                    <TextsContainer_2>
                        <IdentificacaoInfos>
                            <TextsContent>
                                <TextBig_2 className="IC">
                                    Segurança e Controle:
                                </TextBig_2>
                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Identificação rápida de pessoas autorizadas, reduzindo riscos de acessos indevidos.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>

                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Integração com sistemas de controle de acesso (RFID, QR Codes ou biometria).</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>
                            </TextsContent>

                            <TextsContent>
                                <TextBig_2 className="IC">
                                    Profissionalismo e Organização:
                                </TextBig_2>
                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Refletem a identidade visual da empresa e promovem um ambiente corporativo organizado.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>

                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Podem incluir informações importantes, como nome, cargo, setor e foto do colaborador.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>
                            </TextsContent>
                        </IdentificacaoInfos>

                        <IdentificacaoInfos_2>
                            <TextsContent>
                                <TextBig_2 className="IC">
                                    Personalização:
                                </TextBig_2>
                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Fabricados em diferentes materiais (PVC, acrílico, entre outros) e formatos para atender a diversas necessidades.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>
                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Incorporam tecnologias como chips RFID, QR Codes e tarjas magnéticas para múltiplas funções.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>
                            </TextsContent>

                            <TextsContent>
                                <TextBig_2 className="IC">
                                    Facilidade de Integração:
                                </TextBig_2>
                                <CheckTextsContainer_2>
                                    <CheckTextContent>
                                        <TextSmall>Compatíveis com sistemas de acesso, relógios de ponto, catracas e portões automáticos.</TextSmall>
                                        <CheckIconContainer>
                                            
                                        </CheckIconContainer>
                                    </CheckTextContent>
                                </CheckTextsContainer_2>
                            </TextsContent>
                        </IdentificacaoInfos_2>
                            
                        <IdentificacaoTextsBigContainer>
                            <TextBig_3>
                                O Controle de Acesso é fundamental em um mundo onde segurança e eficiência são prioridades. 
                                Ele garante que somente pessoas autorizadas tenham acesso a determinados locais, 
                                fortalecendo a proteção de ativos e dados.
                            </TextBig_3>
                            <TextBig_3>
                                Quer saber mais sobre essa solução para sua empresa? Fale conosco!
                            </TextBig_3>
                        </IdentificacaoTextsBigContainer>
                    </TextsContainer_2>
                }

                <TitleImagageContainer invertido>
                    <Card3D
                        invertido
                        title='Identificação Corporativa'
                        text='A identificação corporativa é essencial para criar um ambiente seguro e profissional, permitindo o reconhecimento imediato de funcionários, 
                        visitantes e prestadores de serviço. Os crachás e dispositivos personalizados são ferramentas versáteis que não apenas garantem controle de acesso,
                         mas também fortalecem a identidade visual da empresa.'
                        minitext='Conectando pessoas, fortalecendo marcas!'
                    />
                    {saibaMais4?  
                        <ButtonContainer>
                            <Button red>
                                <button className="btn" onClick={()=> setSaibaMais4(false)}>Saiba mais</button>
                            </Button>
                        </ButtonContainer>
                        :
                        <ImageContainer>
                            <ImageContent>
                                <Image src={image4} className="image-IC"/>
                            </ImageContent>
                        </ImageContainer>
                    }
                    
                </TitleImagageContainer>
            
            </Container>

        </ProdutosContainerAll>
    )
}

export default Produtos;