import { 
    FirstContent, 
    IconContainer, 
    IconImage,  
    Solutions_Card, 
    Solutions_Container, 
    Solutions_Text_Big, 
    Solutions_Text_Small, 
    Solutions_Text_Small_Container
} from "./services_cards_Styled";
import iconImage from '../../assets/images/Icon_1_services.png'
import iconImage2 from '../../assets/images/Icon_2_services.png'
import iconImage3 from '../../assets/images/Icon_3_services.png'
import iconImage4 from '../../assets/images/trofeu2.png'
import iconImage5 from '../../assets/images/Icon_5_services.png'
import ScrollReveal from "scrollreveal";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, BadgeText } from "../cardPremium/cardStyled";


function ServicesCardsContainer() {
    
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            ScrollReveal().reveal(".card-1", {
              duration: 2000,
              origin: "bottom",
              distance: "45px",
              reset: false,
            });
          }, 0);
          setTimeout(() => {
            ScrollReveal().reveal(".card-3", {
              duration: 2000,
              origin: "bottom",
              distance: "45px",
              delay: 400,
              reset: false,
            });
          }, 0);
          setTimeout(() => {
            ScrollReveal().reveal(".card-2", {
              duration: 2000,
              origin: "bottom",
              distance: "45px",
              delay: 800,
              reset: false,
            });
          }, 0);
          setTimeout(() => {
            ScrollReveal().reveal(".card-4", {
              duration: 2000,
              origin: "bottom",
              distance: "45px",
              reset: false,
            });
          }, 0);
          setTimeout(() => {
            ScrollReveal().reveal(".card-5", {
              duration: 2000,
              origin: "bottom",
              distance: "45px",
              delay: 600,
              reset: false,
            });
          }, 0);
      }, []);

    return (
        <Solutions_Container>
            <Solutions_Card className="card-1" onClick={() => {
                const targetElement = document.getElementById("suporte"); 
                const headerHeight = document.querySelector("header")?.offsetHeight || 100; 

                if (targetElement) {
                // Calcula a posição absoluta do elemento
                const rect = targetElement.getBoundingClientRect();
                const absoluteTop = rect.top + window.scrollY;

                window.scrollTo({
                    top: absoluteTop - headerHeight, // Ajusta pelo header
                    behavior: "smooth", // Scroll suave
                });
                } else {
                console.warn("Elemento com ID 'suporte' não encontrado.");
                }
            }}>
                <Badge>
                    <BadgeText>Premium</BadgeText>
                </Badge>
                
                <FirstContent>
                    <IconContainer>
                        <IconImage src={iconImage}/>
                    </IconContainer>

                    <Solutions_Text_Big>Suporte Humanizado</Solutions_Text_Big>
                    
                    <Solutions_Text_Small style={{marginBottom: '20px', textAlign: 'center'}}>
                        Mais que atendimento, uma parceria de confiança!
                    </Solutions_Text_Small>

                </FirstContent>

            </Solutions_Card>


            <Solutions_Card className="card-3" onClick={() => {
                const targetElement = document.getElementById("mentoria"); 
                const headerHeight = document.querySelector("header")?.offsetHeight || 100; 

                if (targetElement) {
                // Calcula a posição absoluta do elemento
                const rect = targetElement.getBoundingClientRect();
                const absoluteTop = rect.top + window.scrollY;

                window.scrollTo({
                    top: absoluteTop - headerHeight, // Ajusta pelo header
                    behavior: "smooth", // Scroll suave
                });
                } else {
                    console.warn("Elemento com ID 'suporte' não encontrado.");
                }
            }}>
                <FirstContent>
                    <IconContainer>
                        <IconImage src={iconImage3}/>
                    </IconContainer>

                    <Solutions_Text_Big>Mentoria em Departamento Pessoal</Solutions_Text_Big>
                    
                    <Solutions_Text_Small style={{marginBottom: '20px', textAlign: 'center'}}>
                        Soluções práticas para otimizar sua gestão!
                    </Solutions_Text_Small>

                </FirstContent>
                
            </Solutions_Card>
            
            <Solutions_Card className="card-2" onClick={() => {
                const targetElement = document.getElementById("implantacao"); 
                const headerHeight = document.querySelector("header")?.offsetHeight || 90; 

                if (targetElement) {
                // Calcula a posição absoluta do elemento
                const rect = targetElement.getBoundingClientRect();
                const absoluteTop = rect.top + window.scrollY;

                window.scrollTo({
                    top: absoluteTop - headerHeight, // Ajusta pelo header
                    behavior: "smooth", // Scroll suave
                });
                } else {
                    console.warn("Elemento com ID 'suporte' não encontrado.");
                }
            }}>
                <FirstContent>
                    <IconContainer>
                        <IconImage src={iconImage2}/>
                    </IconContainer>

                    <Solutions_Text_Big>Implantação Especializada</Solutions_Text_Big>
                    
                    <Solutions_Text_Small style={{marginBottom: '20px', textAlign: 'center'}}>
                        A solução certa para cada segmento, com a experiência que você pode confiar!
                    </Solutions_Text_Small>

                </FirstContent>
                
            </Solutions_Card>

            <Solutions_Card className="card-4">
                <IconContainer>
                    <IconImage src={iconImage4}/>
                </IconContainer>

                <Solutions_Text_Big>Liderança e Exclusividade</Solutions_Text_Big>

                <Solutions_Text_Small_Container>
                    <Solutions_Text_Small style={{marginBottom: '20px', textAlign: 'center'}}>
                        Torne-se um cliente Premium com a nossa equipe especializada!
                    </Solutions_Text_Small>

                </Solutions_Text_Small_Container>
                
            </Solutions_Card>

            <Solutions_Card className="card-5">
                <IconContainer>
                    <IconImage src={iconImage5}/>
                </IconContainer>

                <Solutions_Text_Big style={{marginBottom: '20px'}}>25 anos de experiência no mercado</Solutions_Text_Big>

                <Solutions_Text_Small_Container>
                    <Solutions_Text_Small style={{marginBottom: '20px', textAlign: 'center'}}>
                        Confiança e Excelência!
                    </Solutions_Text_Small>

                </Solutions_Text_Small_Container>
                
            </Solutions_Card>

            <Solutions_Card className="card-6"/>

        </Solutions_Container>
    )
}

export default ServicesCardsContainer;