import { useEffect } from "react";
import { BackgroundImage, Container, ContainerAll, Degrade, Degrade_2, SecullumRevendaContainer, SecullumRevendaImage, StyledWrapper, SuportTitle, TextContainer, TextSmall } from "./implantacao_styled";
import ScrollReveal from "scrollreveal";
import check from '../../assets/images/iconscheck1.png'
import secullum_revenda from '../../assets/images/secullum_revenda.png'


function ImplantacaoEspecializada() {

    useEffect(() => {
        ScrollReveal().reveal(".card", {
          reset: false,
          afterReveal: (el) => {
            if (el instanceof HTMLElement) {
              el.classList.add("revealed"); // Garantimos que 'el' é um HTMLElement
            }
          },
        });

        ScrollReveal().reveal(".title", {
            duration: 2000, // Duração da animação
            origin: "bottom", // Origem da animação
            distance: "70px", // Distância do movimento
            reset: false, // Não reinicia a animação ao rolar novamente    
          });

        ScrollReveal().reveal(".paragraph", {
            duration: 2800, // Duração da animação
            origin: "left", // Origem da animação
            distance: "60px", // Distância do movimento
            reset: false, // Não reinicia a animação ao rolar novamente    
          });
        
      }, []);

    return(
        <ContainerAll>
            <Container>
                <Degrade/>
                <TextContainer>
                    <SuportTitle id="suporte" className="title">IMPLANTAÇÃO ESPECIALIZADA</SuportTitle>

                    <TextSmall className="paragraph">
                        Somos a única representante Secullum com expertise comprovada na implantação de sistemas de ponto
                        e controle de acesso em diversos segmentos, como indústrias, agronegócios, e empresas de grande porte.
                    </TextSmall>
                    <TextSmall className="paragraph">
                        Com mais de 25 anos de experiência, nossa equipe realiza uma análise personalizada das necessidades
                        da sua empresa, garantindo uma implantação assertiva, eficiente e alinhada às particularidades
                        de cada setor. Nosso objetivo é economizar tempo, evitar erros e proporcionar uma transição tranquila
                        para o uso de sistemas modernos e confiáveis.
                    </TextSmall>
                    <TextSmall className="paragraph">
                        Conte com quem entende do seu negócio para implantar soluções que realmente fazem a diferença!
                    </TextSmall>
                </TextContainer>

                <BackgroundImage>
                    <Degrade phone/>
                    <Degrade_2 phone/>
                </BackgroundImage>
                
                <StyledWrapper>
                    <div className="card">
                        <div className="content">
                            <div className="title" id="implantacao">IMPLANTAÇÃO ESPECIALIZADA</div>
                            <div className="text-content">
                            <p className="paragraph">
                                <div className="p-icon-container">

                                </div>
                                Somos a única representante Secullum com expertise comprovada na implantação de sistemas de ponto
                                e controle de acesso em diversos segmentos, como indústrias, agronegócios, e empresas de grande porte.
                            </p>
                            <p className="paragraph">
                                <div className="p-icon-container">

                                </div>
                                Com mais de 25 anos de experiência, nossa equipe realiza uma análise personalizada das necessidades
                                da sua empresa, garantindo uma implantação assertiva, eficiente e alinhada às particularidades
                                de cada setor. Nosso objetivo é economizar tempo, evitar erros e proporcionar uma transição tranquila
                                para o uso de sistemas modernos e confiáveis. 
                            </p>
                            <p className="paragraph">
                                <div className="p-icon-container">

                                </div>
                                Conte com quem entende do seu negócio para implantar soluções que realmente fazem a diferença! 
                            </p>
                            </div>
                            <SecullumRevendaContainer>
                                <SecullumRevendaImage src={secullum_revenda}/>
                            </SecullumRevendaContainer>

                        </div>
                    </div>       
                </StyledWrapper>
            </Container>
        </ContainerAll>
    )
}

export default ImplantacaoEspecializada;