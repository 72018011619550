import styled from "styled-components";

export const StyledWrapper = styled.div`
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

  .card {
    width: auto;
    height: auto;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 0.2s;
    font-family: 'Lato';

    @media (max-width: 791px) {
      font-size: 14px;
    }
  }

  .card:hover {
  transform: scale(1.05);
  transition: 0.2s;
  filter: drop-shadow(0px 0px 10px rgb(0, 247, 255));
}

.card::after {
  content: "scanning";
  height: fit-content;
  width: 100%;
  color: lightskyblue;
  position: absolute;
  z-index: 2;
  bottom: 13%;
  text-align: center;
  animation: 2s dots  infinite;

  @media (max-width: 1669px) {
    
    transform: translateY(50%);
  }
  
}

.card::before {
  content: "";
  height: 0.1em;
  width: 80%;
  border-radius: -10px;
  background-color: rgb(00, 200, 255);
  position: absolute;
  z-index: 2;
  bottom: 8%;
  animation: 4s grow  infinite;
  align-self: flex-start;
  margin-left: 10%;
  filter: drop-shadow(0px 0px 10px #3fefef) drop-shadow(0px 0px 10px #3fefef);

  @media (max-width: 1669px) {
    
    bottom: -4%;
  }
  @media (max-width: 750px) {
    bottom: -16%;
  }
}

.card svg {
  filter: drop-shadow(0px 0px 10px #3fefef) drop-shadow(0px 0px 50px #3fefef);
  width: 70px;
  height: 140px;

  @media (max-width: 700px) {
    width: 50px;
    height: 100px;
  }
  @media (max-width: 450px) {
    width: 40px;
    height: 80px;
  }
}

path {
  stroke-dasharray: 90;
  animation: loop 4s infinite;
}

@keyframes loop {
  0% {
    stroke-dashoffset: 50;
    stroke-dasharray: 90;
  }

  50% {
    stroke-dashoffset: 100;
    ;
        stroke-dasharray: 50;
  }

  100% {
    stroke-dashoffset: 50;
    stroke-dasharray: 90;
  }
}

@keyframes dots {
  0% {
    content: "Biometria Digital."
  }

  25% {
    content: "Biometria Digital.."
  }

  50% {
    content: "Biometria Digital..."
  }

  75% {
    content: "Biometria Digital"
  }

  100% {
    content: "Biometria Digital."
  }
}

@keyframes grow {
  0% {
    width: 0%;
  }

  50% {
    width: 80%
  }

  100% {
    width: 0%;
  }
}`


