import Header from "../components/header/header";
import { 
    ContainerAll, 
    Divider, 
    InfosSection_2_ContainerAll, 
    Path, 
    Section_2, 
    Section_2_Container, 
    Degrade, 
    Svg, 
    Produtos,
    Produto,
    ImagemProduto,
    DegradeProdutos,
    DegradeProdutos_2,
    TitleProdutos,
    Degrade_2,
} from "./homeStyled";
import { useEffect, useState } from "react";
import Depoimentos from "../components/depoimentos/depoimentos";
import SectionProdutos from "../components/section_produtos/produtos";
import ServicesCardsContainer from "../components/cardsServiços/Services_Cards";
import ScrollReveal from 'scrollreveal'
import SuporteHumanizado from "../components/section_suporte_humanizado/Suporte";
import SlideShow from "./section1/VideoSlideshow";
import { useLocation, useNavigate } from "react-router-dom";
import ImplantacaoEspecializada from "../components/section_implantacao_especializada/Implantacao";
import Mentoria from "../components/section_mentoria/Mentoria";
import produto1 from '../assets/images/notbook_image.png'
import produto2 from '../assets/images/ponto-eletronico.png'
import produto3 from '../assets/images/controle-de-acesso-produto-800x605.png'
import produto4 from '../assets/images/identificacao-corporativa-produto-800x865.png'
import Contato from "../components/contato/Contato";
import Footer from "../components/footer/footer";



function Home() {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.hash) {
        const section = document.querySelector(location.hash);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
        }
    }, [location]);

    useEffect(() => {
        ScrollReveal().reveal(".title-section-2", {
          duration: 2000, // Duração da animação
          origin: "bottom", // Origem da animação
          distance: "60px", // Distância do movimento
          reset: false, // Não reinicia a animação ao rolar novamente
        });
        ScrollReveal().reveal(".produto-1", {
            duration: 2000,
            origin: "bottom",
            distance: "45px",
            reset: false,
            });
        
        
        ScrollReveal().reveal(".produto-2", {
            duration: 2000,
            origin: "bottom",
            distance: "45px",
            delay: 400,
            reset: false,
        });
        
        
        ScrollReveal().reveal(".produto-3", {
            duration: 2000,
            origin: "bottom",
            distance: "45px",
            delay: 800,
            reset: false,
        });
        ;
        
        ScrollReveal().reveal(".produto-4", {
            duration: 2000,
            origin: "bottom",
            distance: "45px",
            delay: 1200,
            reset: false,
        });
        
    }, []);
    

    return (
        <ContainerAll>
            <Header/>
            
            <SlideShow/>

            
            <Divider>
                <Svg viewBox="0 0 60 72">
                    <Path className="a1" d="M0 0 L30 32 L60 0" />
                    <Path className="a2" d="M0 20 L30 52 L60 20" />
                    <Path className="a3" d="M0 40 L30 72 L60 40" />
                </Svg>
            </Divider>
            

            <Section_2 id="section-2">
                <Degrade/>
                <Degrade_2/>
                
                <Section_2_Container>
                    
                    <InfosSection_2_ContainerAll>

                        <ServicesCardsContainer/>

                    </InfosSection_2_ContainerAll>
                </Section_2_Container>
            </Section_2>

            <SuporteHumanizado/>

            <ImplantacaoEspecializada/>
            
            <Mentoria/>

            <Produtos>
                <TitleProdutos id="produtos">PRODUTOS</TitleProdutos>
                <DegradeProdutos/>
                <DegradeProdutos_2/>
                <Produto>
                    <span className="tooltip">Secullum Web</span>
                    <ImagemProduto src={produto1} className="produto-1" onClick={()=> navigate('/#secullumWeb')}/>
                </Produto>

                <Produto>
                    <span className="tooltip">Relógio Eletrônico de Ponto (REP)</span>
                    <ImagemProduto src={produto2} className="produto-2" onClick={()=> navigate('/#rep')}/>
                </Produto>

                <Produto>
                    <span className="tooltip">Controle de Acesso</span>
                    <ImagemProduto src={produto3} className="produto-3" onClick={()=> navigate('/#controleDeAcesso')}/>
                </Produto>

                <Produto>
                    <span className="tooltip">Identificação Corporativa</span>
                    <ImagemProduto src={produto4} className="produto-4" onClick={()=> navigate('/#identificacaoCorporativa')}/>
                </Produto>
            </Produtos>

            <SectionProdutos/>

            <Contato/>

            <Footer/>

        </ContainerAll>
    )
}

export default Home;