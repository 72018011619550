import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardBox = styled.div`
  width: 90%;
  height: 600px;
  padding: 38px 68px;
  box-sizing: border-box;
  border-radius: 20px;
  background: linear-gradient(170deg, rgba(58, 56, 56, 0.623) 0%, rgb(31, 31, 31) 100%);
  position: relative;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.55);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const Badge = styled.span`
  position: absolute;
  overflow: hidden;
  width: 150px;
  height: 150px;
  top: -10px;
  left: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BadgeText = styled.span`
  content: 'Premium';
  position: absolute;
  width: 150%;
  height: 40px;
  background-image: linear-gradient(45deg, #ff6547 0%, #ffb144 51%, #ff7053 100%);
  transform: rotate(-45deg) translateY(-20px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
`;

export const TextsContainer = styled.div`
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
`
export const SecullumImageContainer = styled.div`
    width: 185px;
    height: 176px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
`
export const SecullumImage = styled.img`
    width: 70%;
    height: 70%;
`
export const TextBig = styled.div`
    width: 90%;
    height: auto;
    margin-bottom: 18px;
    font-family: "Archivo Black", sans-serif;
    font-size: 32px;
    font-weight: 400;
    font-style: normal;
    color: white;
`

export const TextSmall = styled.span`
    width: 90%;
    height: auto;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 18px;
    font-weight: 200;
    font-style: normal;
    text-align: justify;
    color: white;
`

