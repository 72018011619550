import styled from "styled-components";
import image from '../../assets/images/ColheitadeiraBG_4.png'

export const Container = styled.div`
    width: 100vw;
    height: 114vh;
    background: url(${image});
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
`
export const Degrade = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 32vh;
    background-image: linear-gradient(to top, rgba(0,0,0,1), rgba(58, 58, 58, 0));
    z-index: 0;
`
export const FormContainer = styled.div`
    position: absolute;
    right: 120px;
    top: 16%;
    width: 100%;
    max-width: 28rem; /* max-w-md */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: linear-gradient(344deg, rgba(40,40,55,1) 0%, rgba(16,16,18,1) 50%);
    border-radius: 0.5rem; /* rounded-lg */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* shadow-md */
    padding: 1.5rem; /* p-6 */

    @media (max-width: 1000px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      background: linear-gradient(344deg, rgba(40,40,55,0.7) 0%, rgba(16,16,18,0.7) 50%);
    }
    @media (max-width: 600px) {
      max-width: 20rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      background: linear-gradient(344deg, rgba(40,40,55,0.7) 0%, rgba(16,16,18,0.7) 50%);
    }
    @media (max-width: 450px) {
      max-width: 18rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      background: linear-gradient(344deg, rgba(40,40,55,0.7) 0%, rgba(16,16,18,0.7) 50%);
    }
    @media (max-width: 450px) {
      max-width: 16rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      background: linear-gradient(344deg, rgba(40,40,55,0.7) 0%, rgba(16,16,18,0.7) 50%);
    }
`;

export const Title = styled.h2`
  font-size: 1.5rem; /* text-2xl */
  font-weight: bold;
  color: #edf2f7; /* text-gray-200 */
  margin-bottom: 1rem; /* mb-4 */
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Espaçamento entre os inputs */
`;

export const Input = styled.input`
  background-color: #4a5568; /* bg-gray-700 */
  color: #edf2f7; /* text-gray-200 */
  font-size: 'Lato';
  border: none;
  border-radius: 8px; /* rounded-md */
  padding: 8px; /* p-2 */
  width: calc(50% - 32px);
  margin-bottom: 1rem;
  transition: all 0.15s ease-in-out;

  &:focus {
    background-color: #2d3748; /* focus:bg-gray-600 */
    outline: none;
    border: 1px solid #4299e1; /* focus:ring-blue-500 */
  }
`;

export const TextArea = styled.textarea`
  background-color: #4a5568; /* bg-gray-700 */
  color: #edf2f7; /* text-gray-200 */
  border: none;
  border-radius: 8px; /* rounded-md */
  padding: 8px; /* p-2 */
  width: 100%;
  height: 100px; /* Altura fixa */
  margin-bottom: 1rem;
  resize: none;
  transition: all 0.15s ease-in-out;

  &:focus {
    background-color: #2d3748; /* focus:bg-gray-600 */
    outline: none;
    border: 1px solid #4299e1; /* focus:ring-blue-500 */
  }
`;

export const Button = styled.button`
  background: linear-gradient(to right, #667eea, #4299e1); /* from-indigo-500 to-blue-500 */
  color: white;
  font-weight: bold;
  padding: 8px 16px; /* py-2 px-4 */
  border-radius: 8px; /* rounded-md */
  margin-top: 1rem; /* mt-4 */
  border: none;
  cursor: pointer;
  transition: background 0.15s ease-in-out; /* duration-150 and ease-in-out */

  &:hover {
    background: linear-gradient(to right, #5a67d8, #2b6cb0); /* hover:bg-indigo-600 hover:to-blue-600 */
}`

export const TextBig = styled.div`
    width: auto;
    height: auto;
    background-color: transparent;
    font-size: 38px;
    letter-spacing: 6px;
    text-shadow: 10px 5px 10px rgb(40, 40, 55);
    font-family: 'Lato';
    color: aliceblue;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 4%;

    @media (max-width: 791px) {
      font-size: 28px;
    }
`