import { useNavigate } from "react-router-dom";
import { ContainerAll, Degrade, FooterInfos, IconContainer, IconImage, IconTextContainer, InfosContainer, LinksContainer, LinksInfosQrcodesContainer, ListElement, ListElementImage, ListElementImageContainer, LocalizacaoIcon, MiniText, NavContainer, Qrcode, QrcodesContainer, SocialMedias, SocialMediasContainer, TextBig, TextInformacoesDeContato, TextsIcon, TextSmallContainer, UnorderedList } from "./footerStyled";
import telefone from '../../assets/images/telefone-icon (1).png'
import perfil_image from '../../assets/images/perfil_image_2.png'
import email from '../../assets/images/email.png'
import qrcode from '../../assets/images/qrcode-scan.jpeg'

function Footer() {

    const navigate = useNavigate()
    return (
        <ContainerAll id="quemsomos">
            <Degrade/>
            <TextBig>
                Quem Somos
                <MiniText>Excelência em Soluções para Gestão e Controle Empresarial</MiniText>
            </TextBig>
            <FooterInfos>
                <TextSmallContainer>
                Somos especialistas em sistemas de ponto e controle de acesso, com mais de 25 anos de experiência. Nosso diferencial está no suporte humanizado, na implantação eficiente 
                e no atendimento personalizado, garantindo que sua empresa tenha soluções sob medida.
                </TextSmallContainer>
                <LinksInfosQrcodesContainer>

                <InfosContainer>
                        <TextInformacoesDeContato>Informações de contato</TextInformacoesDeContato>
                        <IconTextContainer>
                            <LocalizacaoIcon>
                                <div className="loader" />
                            </LocalizacaoIcon>
                            <TextsIcon>
                                Avenida Leopoldino de Oliveira, 4252
                                <div style={{textIndent: '0px', marginTop: '6px'}} className="Bairro">1º e 2º pisos - Centro</div>
                                <div style={{textIndent: '0px'}} className="Cidade-Cep">Uberaba (MG) - CEP: 38.065-165</div>
                            </TextsIcon>
                        </IconTextContainer>

                        <IconTextContainer>
                            <IconContainer>
                                <IconImage src={telefone} alt="telefone"/>
                            </IconContainer>
                            <TextsIcon>+55 (34) 3322-8500</TextsIcon>
                        </IconTextContainer>

                        <IconTextContainer>
                            <IconContainer>
                                <IconImage src={email} alt="email"/>
                            </IconContainer>
                            <TextsIcon>
                                <div style={{textIndent: '30px'}}>atendimento</div>
                                <div style={{textIndent: '0px'}}>@dubrasilsolucoes.com.br</div>
                            </TextsIcon>
                        </IconTextContainer>
                    </InfosContainer>

                    <LinksContainer>
                        <NavContainer>
                            <UnorderedList>
                                <ListElement onClick={()=> navigate('/#section-1')}>Home</ListElement>
                                <ListElement onClick={()=> navigate('/#quemsomos')}>Quem somos</ListElement>
                                <ListElement onClick={() => {
                                    const targetElement = document.getElementById("suporte"); 
                                    const headerHeight = document.querySelector("header")?.offsetHeight || 100; 
            
                                    if (targetElement) {
                                    // Calcula a posição absoluta do elemento
                                    const rect = targetElement.getBoundingClientRect();
                                    const absoluteTop = rect.top + window.scrollY;
            
                                    window.scrollTo({
                                        top: absoluteTop - headerHeight, // Ajusta pelo header
                                        behavior: "smooth", // Scroll suave
                                    });
                                    } else {
                                        console.warn("Elemento com ID 'suporte' não encontrado.");
                                    }
                                }}>Serviços</ListElement>
                                <ListElement onClick={() => {
                                    const targetElement = document.getElementById("produtos"); 
                                    const headerHeight = document.querySelector("header")?.offsetHeight || 100; 
            
                                    if (targetElement) {
                                    // Calcula a posição absoluta do elemento
                                    const rect = targetElement.getBoundingClientRect();
                                    const absoluteTop = rect.top + window.scrollY;
            
                                    window.scrollTo({
                                        top: absoluteTop - headerHeight, // Ajusta pelo header
                                        behavior: "smooth", // Scroll suave
                                    });
                                    } else {
                                        console.warn("Elemento com ID 'suporte' não encontrado.");
                                    }
                                }}>Produtos</ListElement>
                                <ListElement onClick={()=> navigate('/#depoimentos')}>Depoimentos</ListElement>
                                <ListElement onClick={() => {
                                    const targetElement = document.getElementById("contato"); 
                                    const headerHeight = document.querySelector("header")?.offsetHeight || 100; 
            
                                    if (targetElement) {
                                    // Calcula a posição absoluta do elemento
                                    const rect = targetElement.getBoundingClientRect();
                                    const absoluteTop = rect.top + window.scrollY;
            
                                    window.scrollTo({
                                        top: absoluteTop - headerHeight, // Ajusta pelo header
                                        behavior: "smooth", // Scroll suave
                                    });
                                    } else {
                                        console.warn("Elemento com ID 'suporte' não encontrado.");
                                    }
                                }}>Contato</ListElement>
                                <ListElement onClick={() => window.location.href = 'http://helpdesk.dubrasilsolucoes.com.br/HelpDesk/Login.xhtml'}>
                                    <ListElementImageContainer>
                                        <ListElementImage src={perfil_image}/>
                                    </ListElementImageContainer>
                                    Abrir chamado
                                </ListElement>
                            </UnorderedList>
                        </NavContainer>
                    </LinksContainer>

                    <SocialMediasContainer>
                        <SocialMedias>
                            <ul className="example-2">
                                <li className="icon-content">
                                <a data-social="whatsapp" aria-label="Whatsapp" href="https://wa.me/3433228500">
                                    <div className="filled" />
                                    <svg xmlSpace="preserve" viewBox="0 0 24 24" className="bi bi-whatsapp" fill="currentColor" height={24} width={24} xmlns="http://www.w3.org/2000/svg">
                                    <path fill="currentColor" d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                                    </svg>
                                </a>
                                <div className="tooltip">Whatsapp</div>
                                </li>
                                <li className="icon-content">
                                <a data-social="facebook" aria-label="Facebook" href="https://www.facebook.com/dubrasil.solucoes">
                                    <div className="filled" />
                                    <svg xmlSpace="preserve" viewBox="0 0 24 24" className="bi bi-facbook" fill="currentColor" height={24} width={24} xmlns="http://www.w3.org/2000/svg">
                                    <path fill="currentColor" d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z" />
                                    </svg>
                                </a>
                                <div className="tooltip">Facebook</div>
                                </li>
                                <li className="icon-content">
                                <a data-social="instagram" aria-label="Instagram" href="https://www.instagram.com/dubrasilsolucoes/">
                                    <div className="filled" />
                                    <svg xmlSpace="preserve" viewBox="0 0 16 16" className="bi bi-instagram" fill="currentColor" height={16} width={16} xmlns="http://www.w3.org/2000/svg">
                                    <path fill="currentColor" d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                    </svg>
                                </a>
                                <div className="tooltip">Instagram</div>
                                </li>
                            </ul>
                        </SocialMedias>
                    </SocialMediasContainer>

                    <QrcodesContainer>
                        <Qrcode>
                            <div className="card-id567">
                                <img src={qrcode} alt="Minha Imagem" className="qrcode-image"/>
                                <div className="prompt-id567">
                                <div className="token-container">
                                    <svg viewBox="0 0 24 24" fill="none" className="creator-points" xmlns="http://www.w3.org/2000/svg"><path d="M19.4133 4.89862L14.5863 2.17544C12.9911 1.27485 11.0089 1.27485 9.41368 2.17544L4.58674 4.89862C2.99153 5.7992 2 7.47596 2 9.2763V14.7235C2 16.5238 2.99153 18.2014 4.58674 19.1012L9.41368 21.8252C10.2079 22.2734 11.105 22.5 12.0046 22.5C12.6952 22.5 13.3874 22.3657 14.0349 22.0954C14.2204 22.018 14.4059 21.9273 14.5872 21.8252L19.4141 19.1012C19.9765 18.7831 20.4655 18.3728 20.8651 17.8825C21.597 16.9894 22 15.8671 22 14.7243V9.27713C22 7.47678 21.0085 5.7992 19.4133 4.89862ZM4.10784 14.7235V9.2763C4.10784 8.20928 4.6955 7.21559 5.64066 6.68166L10.4676 3.95848C10.9398 3.69152 11.4701 3.55804 11.9996 3.55804C12.5291 3.55804 13.0594 3.69152 13.5324 3.95848L18.3593 6.68166C19.3045 7.21476 19.8922 8.20928 19.8922 9.2763V9.75997C19.1426 9.60836 18.377 9.53091 17.6022 9.53091C14.7929 9.53091 12.1041 10.5501 10.0309 12.3999C8.36735 13.8847 7.21142 15.8012 6.68783 17.9081L5.63981 17.3165C4.69466 16.7834 4.10699 15.7897 4.10699 14.7235H4.10784ZM10.4676 20.0413L8.60933 18.9924C8.94996 17.0479 9.94402 15.2665 11.4515 13.921C13.1353 12.4181 15.3198 11.5908 17.6022 11.5908C18.3804 11.5908 19.1477 11.6864 19.8922 11.8742V14.7235C19.8922 15.2278 19.7589 15.7254 19.5119 16.1662C18.7615 15.3596 17.6806 14.8528 16.4783 14.8528C14.2136 14.8528 12.3781 16.6466 12.3781 18.8598C12.3781 19.3937 12.4861 19.9021 12.68 20.3676C11.9347 20.5316 11.1396 20.4203 10.4684 20.0413H10.4676Z" fill="currentColor" /></svg>
                                </div>
                                <div className="blurry-splash" />
                                <p>WhatsApp<br /><span className="bold-567">QrCode</span> </p>
                                <p className="really-small-text"></p>
                                </div>
                            </div>
                        </Qrcode>
                    </QrcodesContainer>
                </LinksInfosQrcodesContainer>
            </FooterInfos>
        </ContainerAll>
    );
}

export default Footer;