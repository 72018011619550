import { useEffect } from "react";
import { BackgroundImage, Container, ContainerAll, Degrade, Degrade_2, StyledWrapper, SuportTitle, TextContainer, TextSmall } from "./suporte_styled";
import ScrollReveal from "scrollreveal";
import check from '../../assets/images/iconscheck1.png'

function SuporteHumanizado() {

    useEffect(() => {
        ScrollReveal().reveal(".card", {
          reset: false,
          afterReveal: (el) => {
            if (el instanceof HTMLElement) {
              el.classList.add("revealed"); // Garantimos que 'el' é um HTMLElement
            }
          },
        });

        ScrollReveal().reveal(".title", {
            duration: 2000, // Duração da animação
            origin: "left", // Origem da animação
            distance: "70px", // Distância do movimento
            reset: false, // Não reinicia a animação ao rolar novamente    
          });

        ScrollReveal().reveal(".paragraph", {
            duration: 2800, // Duração da animação
            origin: "left", // Origem da animação
            distance: "60px", // Distância do movimento
            reset: false, // Não reinicia a animação ao rolar novamente    
          });
        
      }, []);

    return(
        <ContainerAll>
            <Degrade/>
            <Degrade_2/>
            <Container>
                <TextContainer>
                    <SuportTitle className="title">Suporte Humanizado</SuportTitle>

                    <TextSmall className="paragraph">
                        Nosso suporte é realizado por profissionais certificados e altamente capacitados, prontos para entender
                        e solucionar suas necessidades de forma ágil e eficiente. Sabemos que cada cliente é único, e por isso, 
                        nosso atendimento é personalizado, garantindo uma experiência de excelência.
                    </TextSmall>
                    <TextSmall className="paragraph">
                        Com foco na proximidade e na empatia, estamos ao seu lado em todos os momentos, 
                        oferecendo soluções práticas e assertivas para o seu negócio.
                    </TextSmall>
                    <TextSmall className="paragraph">
                        Conte com uma equipe especializada que transforma desafios em resultados!
                    </TextSmall>
                </TextContainer>

                <BackgroundImage>
                    <Degrade phone/>
                    <Degrade_2 phone/>
                </BackgroundImage>

                <StyledWrapper>
                    <section className="container">
                        <div className="card">
                            <div className="content">
                                <div className="title" id="suporte">Suporte Humanizado</div>
                                <div className="text-content">
                                    <p className="paragraph">
                                        <div className="p-icon-container">
                                            
                                        </div>
                                        Nosso suporte é realizado por profissionais certificados e altamente capacitados, prontos para entender
                                        e solucionar suas necessidades de forma ágil e eficiente. Sabemos que cada cliente é único, e por isso, 
                                        nosso atendimento é personalizado, garantindo uma experiência de excelência.
                                    </p>
                                    <p className="paragraph">
                                        <div className="p-icon-container">
                                            
                                        </div>
                                        Com foco na proximidade e na empatia, estamos ao seu lado em todos os momentos, 
                                        oferecendo soluções práticas e assertivas para o seu negócio. 
                                    </p>
                                    <p className="paragraph">
                                        <div className="p-icon-container">
                                            
                                        </div>
                                        Conte com uma equipe especializada que transforma desafios em resultados! 
                                    </p>
                                </div>
                            </div>
                        </div>       
                    </section>
                </StyledWrapper>
            </Container>
        </ContainerAll>
    )
}

export default SuporteHumanizado;