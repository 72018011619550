import styled, {keyframes} from "styled-components";
import imageSection from "../assets/images/ColheitadeiraBG_4.png"
import section_2_image from "../assets/images/cards_bg_2.png"


export const ContainerAll = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    background-color: black;
`
export const Degrade_2 = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 32vh;
    background-image: linear-gradient(to top, rgba(0,0,0,1), rgba(58, 58, 58, 0));
    z-index: 0;
`

export const Divider = styled.div`
    width: 100%;
    height: auto;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
`
const arrowAnimation = keyframes`
  0% {opacity: 0;}
  40% {opacity: 1;}
  80% {opacity: 0;}
  100% {opacity: 0;}
`;

export const Svg = styled.svg`
  width: 60px;
  height: 172px;
  bottom: 20px;

  @media (max-width: 450px) {
      margin-top: 16px;
    }
`

export const Path = styled.path`
  stroke: #fff;
  fill: transparent;
  stroke-width: 1px;
  animation: ${arrowAnimation} 2s infinite;
  
  /* Variantes para delay */
  &.a1 {
    animation-delay: -0.5s;
  }

  &.a2 {
    animation-delay: -0.3s;
  }

  &.a3 {
    animation-delay: -0.1s;
  }
`;

export const Section_2 = styled.div`
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${section_2_image});
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;

    @media (max-width: 1191px) {
      height: auto;
      padding: 8vh 0 8vh 0;
      box-sizing: border-box;
    }
`

export const Degrade = styled.div`
    position: absolute;
    left: 0;
    top: -2px;
    width: 100%;
    height: 42vh;
    background-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(58, 58, 58, 0));
    z-index: 0;
`

export const Section_2_Container = styled.div`
    width: 78%;
    height: auto;
    display: flex;
    background-color: transparent;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1523px){
      width: 100%;
    }
`

export const InfosSection_2_ContainerAll = styled.div`
    width: 80%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 18px;
`

export const Produtos = styled.div`
    width: 100vw;
    height: 300px;
    margin-top: 200px;
    padding: 0px 22%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(40, 40, 55, 1);
    position: relative;

    @media (max-width: 700px) {
      padding: 0 4%;
    }
`
export const DegradeProdutos = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 16vh;
    background-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(40, 40, 55, 0));
`
export const DegradeProdutos_2 = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 16vh;
    background-image: linear-gradient(to top, rgba(0,0,0,1), rgba(40, 40, 55, 0));
`
export const Produto = styled.div`
  width: 230px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;
  position: relative;

  .tooltip {
    position: absolute;
    top: calc(-60% - 0.75rem);
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(144deg, rgba(40,40,55,0.4) 0%, rgba(16,16,18,0.2) 70%);
    color: var(--clr-white, white); /* Valor padrão se --clr-white não estiver definido */
    padding: 0.5rem 1rem;
    cursor: auto;
    display: none;
    animation: showTooltip 300ms ease-in-out;
    transform-origin: bottom;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-align: center;

    @media (max-width: 791px) {
       font-size: 14px;
    }

    /* Seta */
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 8px 0 8px;
      border-color: var(--clr, #e0e0e0) transparent transparent transparent;
      bottom: -9px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover {
    color: var(--clr-white, #fff);
    border-color: transparent;
  }

  &:hover .tooltip {
    display: block;
  }

  @keyframes showTooltip {
    0% {
      opacity: 0;
      transform: scaleY(0) translateX(-50%);
    }

    100% {
      opacity: 1;
      transform: scaleY(1) translateX(-50%);
    }
  }
`;

export const ImagemProduto = styled.img`
  visibility: hidden;
  width: 100%;
  height: auto;
  transition: 0.6s;

  &:hover{
      transform: scale(1.04);
  }
`

export const TitleProdutos = styled.div`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -250%);
    font-size: 38px;
    letter-spacing: 6px;
    text-shadow: 10px 5px 10px rgb(40, 40, 55);
    font-family: 'Lato';
    color: aliceblue;

    @media (max-width: 791px) {
      font-size: 28px;
    }
`

