import styled from "styled-components";
import section_3_image from "../../assets/images/suporte_raiz_7.png"
import suporte_img from "../../assets/images/suporte_raiz_5.png"


export const ContainerAll = styled.div`
    width: 100vw;
    height: calc(100vh - 60px);
    margin-top: 80px;
    background: url('${section_3_image}');
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (min-width: 1669px) {
      background-size: contain;
      background-position: bottom right;
    }
    @media (max-width: 1400px) {
      background: none;
      height: auto;
    }
  
`

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    @media (max-width: 1400px) {
      
      flex-direction: column;
    }
`
export const  TextContainer = styled.div`
  height: auto;
  width: 100vw;
  padding: 40px;
  box-sizing: border-box;
  display: none;
  flex-direction: column;
  
  @media (max-width: 1400px) {
      
    display: flex;
  }
`
export const BackgroundImage = styled.div`
  width: 100vw;
  height: 70vh;
  background: url('${section_3_image}');
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center bottom;
  display: none;
  position: relative;

  @media (max-width: 1400px) {
    display: flex;
    background: url('${suporte_img}');
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (max-width: 1000px) {
    background-position: calc(100% + 100px) bottom;
  }
`
export const SuportTitle = styled.div`
  width: 100%;
  font-size: 38px;
  margin-bottom: 40px;
  visibility: hidden;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: white;

  @media (max-width: 791px) {
    width: 40%;
    font-size: 28px;
  }
`
export const TextSmall = styled.div`
  margin-bottom: 16px;
  visibility: hidden;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  text-align: justify;
  line-height: 1.4em;
  color: #e6e6e6;
  position: relative;

  @media (max-width: 791px) {
    font-size: 14px;
  }
  @media (max-width: 450px) {
    font-size: 13px;
  }
`

export const StyledWrapper = styled.div`
  .container {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1400px) {
      
    display: none;
  }

  .card {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100vw;
    padding: 2em 0;
  }
  /*
  .card::before,
  .card::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    background: #e6e6e6;
    height: 2px;
    */  

  .card::before {
    width: 0;
    opacity: 0;
    transition: opacity 0 ease, width 0 ease;
    transition-delay: 0.5s;
  }

  .card::after {
    width: 100%;
    background: #0d0d0d;
    transition: width 1.5s ease;
  }

  .card .content {
    width: 100vw;
    padding: 80px 120px 120px 120px;
    box-sizing: border-box;
  }

  .card .title {
    width: 100%;
    font-size: 38px;
    visibility: hidden;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 2px;
    color: white;

    @media (max-width: 791px) {
      width: 40%;
      font-size: 28px;
    }
  }

  .card .text-content {
    height: auto;
    width: 40%;
    transform: translateY(1em);
    transition: all 0.55s ease;

    @media (max-width: 1400px) {
      width: 100%;
    }
    @media (max-width: 450px) {
      width: 100%;
      text-shadow: 2px 2px 4px black;
    }
  }

  .card .text-content p {
    visibility: hidden;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    text-align: justify;
    line-height: 1.4em;
    color: #e6e6e6;
    position: relative;

    @media (max-width: 791px) {
       font-size: 14px;
    }
    @media (max-width: 450px) {
       font-size: 13px;
    }
  }
  
  .p-icon-container{
    width: 23px;
    height: 23px;
    position: absolute;
    top: 0;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p-icon-image{
    width: 100%;
    height: 100%;
  }

  .card.revealed {
    width: 100vw;
  }

  .card.revealed::before {
    width: 100%;
    opacity: 1;
    transition: opacity 1.5s ease, width 1.5s ease;
    transition-delay: 0;
  }

  .card.revealed::after {
    width: 0;
    opacity: 0;
    transition: width 0 ease;
  }

  .card.revealed .logo {
    margin-bottom: 0.5em;
  }

  .card.revealed .text_content {
    max-height: 10em;
    transform: none;
  }`;


export const Degrade = styled.div<{phone?: boolean}>`
    position: absolute;
    left: 0;
    top: -2px;
    width: 100%;
    height: ${({phone}) => phone? '8vh' : '32vh'};
    background-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(58, 58, 58, 0));
    z-index: 0;

    @media (max-width: 1400px) {
      height: ${({ phone }) => (phone ? '24vh' : '32vh')};
    }
`
export const Degrade_2 = styled.div<{phone?: boolean}>`
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: ${({phone}) => phone? '8vh' : '32vh'};
    background-image: linear-gradient(to top, rgba(0,0,0,1), rgba(58, 58, 58, 0));
    z-index: 0;

    @media (max-width: 1400px) {
      height: ${({ phone }) => (phone ? '24vh' : '32vh')};
    }
`