import { Button,  Container, Degrade, Form, FormContainer, Input, TextArea, TextBig, Title } from "./contato_Styled";

function Contato() {
    return (
        <Container>
            <Degrade/>
            <TextBig id="contato">CONTATO</TextBig>
            <FormContainer>
                <Title>Formulário de contato</Title>
                <Form>
                <Input type="text" placeholder="Nome completo" />
                <Input type="email" placeholder="Email" />
                <Input type="number" placeholder="Telefone" />
                <Input type="date" placeholder="Date of Birth" />
                <TextArea placeholder="Mensagem" />
                <Button>Enviar</Button>
                </Form>
            </FormContainer>
        </Container>
    )
}

export default Contato;