import styled from "styled-components";

export const Solutions_Container = styled.div`
    width: 100%;
    height: auto;
    column-gap: 20px;
    row-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: end;
`
export const Solutions_Card = styled.div`
    width: calc((100%/3) - 60px);
    min-width: 300px;
    height: 260px;
    border-radius: 12px;
    padding: 20px 30px 30px 30px;
    box-sizing: border-box;
    background: linear-gradient(144deg, rgba(40,40,55,1) 0%, rgba(16,16,18,1) 50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    transition: all .3s;
    visibility: hidden;
    color: white;
    position: relative;

    &:hover{
        border: #c0c0c0 2px solid;
        border-radius: 2.5rem 0 2.5rem 0;
    }

    @media (max-width: 791px) {
        height: 220px;
    }

    &.card-4 {
        @media (max-width: 791px) {
        display: none;
        }
    }

    &.card-5 {
        @media (max-width: 791px) {
        display: none;
        }
    }

    &.card-6 {

        @media (max-width: 1191px) {
        display: none;
        }
    }
    
`


export const FirstContent = styled.div`
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border-radius: 15px;

`;

export const IconContainer = styled.div`
    width: 65px;
    height: 60px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 791px) {
        width: 50px;
        height: 50px;
    }

`
export const IconImage = styled.img`
    width: 100%;
    height: 100%;
`
export const Solutions_Text_Big = styled.div`
    font-size: 21px;
    margin-bottom: 20px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    text-align: center;

    @media (max-width: 791px) {
       
        font-size: 18px;
    }

`
export const Solutions_Text_Small_Container = styled.div`
    height: 100%;
    display: flex;
`
interface Solutions_Text_Small_Props {
    border?: boolean
    padding?: boolean
}
export const Solutions_Text_Small = styled.div<Solutions_Text_Small_Props>`
    text-indent: 20px;
    margin-bottom: 6px;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    text-align: justify;
    position: relative;
    border-top: ${({border})=> border? '1px solid #bd9f67' : ''};
    border-bottom: ${({border})=> border? '1px solid #bd9f67' : ''};
    padding-top: ${({padding})=> padding? '6px' : ''};
    padding-bottom: ${({padding})=> padding? '6px' : ''};
    box-sizing: ${({padding})=> padding? 'border-box' : ''};

    @media (max-width: 791px) {
        font-size: 14px;
    }

`