import styled, {keyframes} from "styled-components";
import section_4_image from "../../assets/images/section_5_bg_3.png"
import bg_1 from '../../assets/images/bgp_1.png'
import bg_2 from '../../assets/images/bgp_2.png'
import bg_3 from '../../assets/images/bgp_3_3.png'
import bg_4 from '../../assets/images/bgp_4_4.png'


export const ProdutosContainerAll = styled.div`
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
`

export const Container_1 = styled.div`
    width: 100%;
    height: auto;
    padding-top: 160px;
    box-sizing: border-box;
    display: flex;
    background: url(${bg_1});
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center bottom;
    position: relative;

    @media (max-width: 1300px) {  
        background-position: calc(0% - 200px) bottom;
        flex-direction: column;
        row-gap: 4vh;
    }


    `
export const Container = styled.div<{black?: boolean}>`
    width: 100%;
    height: auto;
    padding-top: 80px;
    box-sizing: border-box;
    display: flex;
    background: url(${section_4_image});
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center bottom;
    position: relative;

    &#rep {
        background: url(${bg_2});
        background-size: cover; 
        background-repeat: no-repeat; 
        background-position: center bottom;
        @media (max-width: 1300px) {
            flex-direction: column-reverse;
        }
    }
    &#controleDeAcesso{
        background-size: cover; 
        background-repeat: no-repeat; 
        background-position: center bottom;
        background: url(${bg_3});

        @media (max-width: 1300px) {  
            background-position: calc(0% - 200px) bottom;
            flex-direction: column;
        }
    }
    &#identificacaoCorporativa {
        background-size: cover; 
        background-repeat: no-repeat; 
        background-position: center bottom;
        background: url(${bg_4});
        padding-bottom: 120px;
        @media (max-width: 1300px) {
            background-position: calc(100% + 400px) bottom;
            flex-direction: column-reverse;
        }
    }
    @media (max-width: 1300px) {
        flex-direction: column;
        row-gap: 4vh;
    }

    
`
export const Degrade = styled.div`
    position: absolute;
    left: 0;
    top: -2px;
    width: 100%;
    height: 42vh;
    background-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(58, 58, 58, 0));
    z-index: 0;
`
export const Degrade_2 = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 42vh;
    background-image: linear-gradient(to top, rgba(0,0,0,1), rgba(58, 58, 58, 0));
    z-index: 0;
`
export const TitleImagageContainer = styled.div<{invertido?: boolean}>`
    width: 40%;
    height: calc(100vh - 60px);
    display: flex;
    margin: ${({invertido})=> invertido? '0px 120px 0px 20px' : '0px 20px 0px 120px'};
    flex-direction: column;
    align-items: center;
    position: relative;

    @media (max-width: 1300px) {
        height: auto;
        width: 100%;
        row-gap: 4vh;
        margin: 0;
        padding: 0px 8px;
        box-sizing: border-box;
    }
`

export const ImageContainer = styled.div`
    width: 100%;
    height: auto;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ImageContent = styled.div`
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    transition: 0.6s;
    &:hover{
        transform: scale(1.04);

    }
`
export const Image = styled.img<{saibaMais?: boolean}>`
    height: auto;
    width: 100%;
    max-height: 44vh;
    z-index: 3;

    &.image-SW{
        margin-left: 31vw;
    }
`
export const ButtonContainer = styled.div`
    width: 60%;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1300px) {
        width: 100%;
        height: 60%;
    }
`
export const ImageBigContainer = styled.div`
    width: 60%;
    height: 100%;
    min-height: 60vh;
    max-height: 90vh;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1300px) {
        width: 100%;
    }
`
export const ImageCDA = styled.img`
    height: auto;
    width: 70%;
    max-width: 1020px;
    transition: 0.4s;
    visibility: hidden;
    &:hover{
        transform: scale(1.02);
    }
    @media (max-width: 1300px) {
        max-height: 68vh;
    }
`
export const ImageSW = styled.img`
    height: auto;
    width: 80%;
    max-width: 1020px;
    transition: 0.4s;
    visibility: hidden;
    &:hover{
        transform: scale(1.02);
    }
    @media (max-width: 1300px) {
        max-height: 68vh;
    }
`
export const ImageREP = styled.img`
    height: auto;
    width: 90%;
    max-width: 1020px;
    transition: 0.4s;
    visibility: hidden;
    &:hover{
        transform: scale(1.02);
    }
    @media (max-width: 1300px) {
        max-height: 68vh;
    }
`
export const ImageIC = styled.img`
    height: auto;
    width: 60%;
    max-width: 880px;
    transition: 0.4s;
    visibility: hidden;
    &:hover{
        transform: scale(1.02);
    }
    @media (max-width: 1300px) {
        max-height: 68vh;
    }
`


export const SecullumWebTextsBigContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 30px 120px 30px 200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: linear-gradient(244deg, rgba(119, 119, 185, 0.4) 0%, rgba(16,16,18,0.2) 70%);

    @media (max-width: 1300px) {
        padding: 8px 16px;
        box-sizing: border-box;
        margin-right: 0;
    }

`



export const TextsContainer = styled.div<{inverted?: boolean}>`
    width: 60%;
    height: 100%;
    flex-grow: 1;
    box-sizing: border-box;
    background: transparent;
    position: relative;

    @media (max-width: 1300px) {
        width: 100%;
        height: auto;
        margin-top: 8vh;
    }
`
export const TextsContainer_2 = styled.div<{inverted?: boolean}>`
    width: 54%;
    height: auto;
    box-sizing: border-box;
    background: transparent;

    @media (max-width: 1300px) {
        width: 100%;
        height: auto;
    }
`



export const CheckTextContainer = styled.div<{first?: boolean}>`
    width: 100%;
    height: auto;
    padding: 8px 120px;
    box-sizing: border-box;
    display: flex;

    @media (max-width: 1300px) {
        padding: 8px 16px;
    }
`
export const CheckTextsContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 8px 28px;
    box-sizing: border-box;

    @media (max-width: 450px) {
        padding: 8px 16px;
    }
` 
export const CheckTextsContainer_2 = styled.div`
    width: 100%;
    height: auto;
    padding: 8px 0px 8px 28px;
    box-sizing: border-box;

    @media (max-width: 1300px) {
       padding: 0;
    }
` 
export const CheckTextContent = styled.div<{width?: boolean}>`
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    transition: 0.4s;
    border-radius: 4px 0px 0px 0px;
    overflow: hidden;
    background: linear-gradient(to bottom right, rgb(255, 255, 255, 0.6) 0%, rgb(55, 55, 55, 0.01) 12%);

    &:hover{
        transform: scale(1.02);
    }
`
export const CheckIconContainer = styled.div`
    width: 22px;
    height: 22px;
    position: absolute;
    left: 0;
`
export const ChekIcon = styled.img`
    width: 100%;
    height: 100%;
`

export const ButtonContainerSecullumWeb = styled.div`
    width: 60%;
    height: 100%;
    min-height: 80vh;
    max-height: 90vh;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1300px) {
        width: 100%;
        height: 60%;
    }
`

export const ButtonContainerControleDeAcesso = styled.div`
    width: 60%;
    height: 100%;
    min-height: 80vh;
    max-height: 90vh;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1300px) {
        width: 100%;
        height: 60%;
    }
`

export const Button = styled.div<{ red?: boolean }>`
  .btn {
    width: 150px;
    height: 36px;
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
    background-color: ${({ red }) =>
    red
      ? 'rgba(232, 31, 31, 0.87)'
      : 'rgb(14, 14, 26)'};
    color: rgb(234, 234, 234);
    font-weight: 700;
    font-family: 'Lato';
    transition: 0.6s;
  }

  .btn:active {
    scale: 0.92;
  }

  .btn:hover {
    background: ${({ red }) =>
    red
      ? 'linear-gradient(270deg, rgba(78, 2, 2, 0.68) 0%, rgba(232, 31, 31, 0.87) 60%)'
      : 'linear-gradient(270deg, rgba(2, 29, 78, 0.681) 0%, rgba(31, 215, 232, 0.873) 60%)'};
    color: ${({ red }) =>
    red
      ? 'rgb(196, 188, 188)'
      : 'rgb(4, 4, 38)'};
  }
`;



export const TextBig = styled.div<{padding?: boolean}>`
    width: 100%;
    height: 10%;
    margin-bottom: 30px;
    padding: ${({padding})=> padding? '0px 80px 0px 300px' : '0px 80px'};
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: justify;
    color: white;
    position: relative;
    z-index: 3;

    &#secullumTextBig {
        @media (max-width: 1300px) {
            padding: 0;
        }
    }

    @media (max-width: 791px) {
       font-size: 18px;
    }
    @media (max-width: 450px) {
       font-size: 14px;
    }
`
interface TextBig_2_Props {
    textType?: boolean
    textType_2?: boolean
}
export const TextBig_2 = styled.div<TextBig_2_Props>`
    padding: ${({textType})=> textType? '0' : '0px 28px'} ;
    margin-top: 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
    text-align: justify;
    font-size: 20px;
    font-family: 'Lato';
    color: white;
    z-index: 3;
    position: relative;

    @media (max-width: 791px) {
       font-size: 18px;
    }
    @media (max-width: 450px) {
        font-size: 14px;
        text-align: left;
    }
    &.REPTextBig {
        @media (max-width: 1300px) {
            padding: 0px 8px;
            text-align: justify;
        }
    }
    &.CDA {
        @media (max-width: 1300px) {
            padding: 0;
        }
    }
    &.IC {
        @media (max-width: 1300px) {
            padding: 0;
        }
    }
    &.rep-text-big {
        @media (max-width: 450px) {
            padding: 0px 16px;
            text-align: justify;
        }
    }

`
export const TextBig_3 = styled.div`
    padding: 0px 0px 0px 28px;
    margin-top: 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
    text-align: justify;
    font-size: 20px;
    font-family: 'Lato';
    color: white;
    z-index: 3;

    @media (max-width: 1300px) {
       padding: 0;
    }

    @media (max-width: 791px) {
       font-size: 18px;
    }
    @media (max-width: 450px) {
       font-size: 14px;
    }
`

export const TextSmall = styled.div<{padding?: boolean}>`
    padding-bottom: 8px;
    border-bottom: 1px solid #282837;
    box-sizing: border-box;
    backdrop-filter: blur(20px);
    font-size: 15px;
    font-weight: 300;
    font-family: 'Lato';
    text-align: justify;
    color: white;

    &.secullumTextSmall {
        border-bottom: none;
        text-indent: 0;
    }

    @media (max-width: 791px) {
       font-size: 14px;
    }
    @media (max-width: 450px) {
        font-size: 13px;
        hyphens: auto;
        overflow-wrap: break-word;
    }
`
export const TextSmall_2 = styled.div<{textType?: boolean}>`
    text-align: ${({textType})=> textType? 'justify' : 'center'};
    font-size: 15px;
    font-weight: 300;
    font-family: 'Lato';
    position: relative;
    color: white;

    @media (max-width: 791px) {
       font-size: 14px;
    }
    @media (max-width: 450px) {
        font-size: 13px;
        hyphens: auto;
        overflow-wrap: break-word;
    }
`
export const Span = styled.span`
    font-size: 19px;
    font-weight: 800;

    @media (max-width: 791px) {
       font-size: 17px;
    }
`


export const VideoContainer = styled.div`
    width: 100vw;
    height: calc(100vh - 60px);
    margin-top: 80px;
    box-sizing: border-box;
    position: relative;

    @media (max-width: 850px) {
      height: 50vh;
    }
    @media (max-width: 650px) {
      height: 40vh;
    }
    @media (max-width: 450px) {
      height: 35vh;
    }
    @media (max-width: 350px) {
      height: 30vh;
    }
`
export const VideoBackground = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Faz o vídeo ocupar a tela inteira */
    z-index: 3;
    
`;
export const DegradeVideo = styled.div`
    position: absolute;
    left: 0;
    top: -2px;
    width: 100%;
    height: 25%;
    background-image: linear-gradient(to bottom, rgb(0, 0, 0), rgba(12, 12, 12, 0));
    z-index: 4;
`



export const REPinformacoes = styled.div`
    height: auto;
    width: 100%;
    padding-left: 120px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 1300px) {
        padding-left: 0;
    }

`
export const REPTextBig = styled.div`
    width: 100%;
    height: auto;
    padding: 8px 0px 8px 120px;
    box-sizing: border-box;
    background: linear-gradient(144deg, rgba(119, 119, 185, 0.4) 0%, rgba(16,16,18,0.2) 70%);
    position: relative;
    z-index: 4;

    @media (max-width: 1300px) {
        margin-top: 8vh;
        padding: 8px;
        box-sizing: border-box;
    }
`
export const CardsContainer = styled.div<{ hovered: boolean }>`
    width: 100%;
    height: auto;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    @media (max-width: 1300px) {
        margin-bottom: 56px;
    }
`
export const InfosContainer = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
`
// Estilo para os cards individuais
export const Card = styled.div<{isHovered: boolean }>`
  height: auto;
  width: 25%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  color: white;
  cursor: pointer;
  transition: 400ms;
  background: transparent;

  @media (max-width: 600px) {
    padding: 10px;
  }
  @media (max-width: 400px) {
    padding: 0;
  }

  ${({ isHovered }) =>
    isHovered
      ? `transform: scale(1.2); filter: none;`
      : `transform: scale(1.1); filter: blur(10px);`};
`;
export const IconContainer = styled.div`
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

`
export const IconImage = styled.img`
    width: 100px;
    height: auto;

    @media (max-width: 700px) {
        width: 80px;
    }
    @media (max-width: 500px) {
        width: 70px;
    }
    @media (max-width: 400px) {
        width: 60px;
    }
`
export const ArrowContainer = styled.div`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 110%;
    left: 40%;
`
export const ArrowImg = styled.img`
    width: 100%;
    height: 100%;
`



export const ControleDeAcessoInfos = styled.div<{card_4?: boolean}>`
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    padding: 0px 120px 0px 60px;
    box-sizing: border-box;
    display: flex;
    column-gap: 16px;

    @media (max-width: 1300px) {
        padding: 0px 28px;
        column-gap: 32px;
    }
    @media (max-width: 600px) {
        column-gap: 16px;
    }
    @media (max-width: 450px) {
        padding: 0px 16px;
    }
`
export const ControleDeAcessoInfos_2 = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    padding: 0px 120px 0px 60px;
    box-sizing: border-box;
    display: flex;
    column-gap: 16px;

    @media (max-width: 1300px) {
        padding: 0px 28px;
        column-gap: 32px;
    }
    @media (max-width: 600px) {
        column-gap: 16px;
    }
    @media (max-width: 450px) {
        padding: 0px 16px;
    }
`
export const TextsContent = styled.div`
    height: auto;
    width: calc(50% - 16px);
    display: flex;
    flex-direction: column;

`
export const ControleDeAcessoTextsBigContainer = styled.div`
    width: 100%;
    height: auto;
    margin-right: 120px;
    padding: 0px 120px 0px 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: linear-gradient(244deg, rgba(119, 119, 185, 0.4) 0%, rgba(16,16,18,0.2) 70%);

    @media (max-width: 1300px) {
        padding: 0px 28px;
    }
    @media (max-width: 450px) {
        padding: 0px 16px;
    }
`



export const IdentificacaoInfos = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    padding: 0px 80px 0px 120px;
    box-sizing: border-box;
    display: flex;
    column-gap: 16px;

    @media (max-width: 1300px) {
        padding: 0px 28px;
        column-gap: 32px;
    }
    @media (max-width: 600px) {
        column-gap: 16px;
    }
    @media (max-width: 450px) {
        padding: 0px 16px;
    }
`
export const IdentificacaoInfos_2 = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    padding: 0px 80px 0px 120px;
    box-sizing: border-box;
    display: flex;
    column-gap: 16px;

    @media (max-width: 1300px) {
        padding: 0px 28px;
        column-gap: 32px;
    }
    @media (max-width: 600px) {
        column-gap: 16px;
    }
    @media (max-width: 450px) {
        padding: 0px 16px;
    }
`
export const IdentificacaoTextsBigContainer = styled.div`
    width: 100%;
    height: auto;
    margin-right: 120px;
    padding: 0px 80px 0px 120px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: linear-gradient(144deg, rgba(119, 119, 185, 0.4) 0%, rgba(16,16,18,0.2) 70%);

    @media (max-width: 1300px) {
       padding: 0px 28px;
    }
    @media (max-width: 450px) {
        padding: 0px 16px;
    }
`