import styled, {keyframes} from "styled-components";

export const Section_1 = styled.div`
    width: 100vw;
    height: 100vh;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center bottom;

    @media (max-width: 950px) {
      height: 70vh;
    }

`

export const Degrade = styled.div`
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 16vh;
    z-index: 3;
    background-image: linear-gradient(to top, rgba(0,0,0,1), rgba(255, 255, 255, 0));
`

export const SliderShow = styled.div`
    width: 100vw;
    height: 100%;
    display: flex;
    background-color: transparent;
    position: relative;
    z-index: 2;

    @media (max-width: 1200px) {
        height: 70vh;
    }
    @media (max-width: 950px) {
        height: 50vh;
    }
    @media (max-width: 750px) {
        height: 45vh;
    }
    @media (max-width: 550px) {
        height: 40vh;
    }
    @media (max-width: 450px) {
        height: 35vh;
    }


`

export const VideoBackground = styled.video<{ isTransitioning: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Faz o vídeo ocupar a tela inteira */
    z-index: -1; /* Coloca o vídeo atrás de outros conteúdos */

    opacity: ${(props) => (props.isTransitioning ? 0 : 1)};
    transition: opacity 0.3s ease; /* Duração da transição */
`;
export const SlideContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 3;
`;

export const SlideTexts = styled.div`
    width: 360px;
    height: auto;
    position: absolute;
    top: 20%;
    right: 0px;
    margin-right: 140px;
    backdrop-filter: blur(10px);
    padding: 28px;
    box-sizing: border-box;
    border-radius: 18px;
    font-size: 38px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    display: flex;
    flex-direction: column;

    @media (max-width: 1200px) {
      width: 80vw;
      height: auto;
      font-size: 32px;
      margin-right: 0;
      top: 100%;
      right: 50%;
      transform: translateX(49%);
      align-items: center;
    }

`;
export const SlideText = styled.div`
    width: auto;
    height: auto;
    margin-bottom: 18px;

    @media (max-width: 1200px) {
      text-align: center;
    }
`
export const Slide = styled.div<{isVisible: boolean}>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;

    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
    transition: opacity 0.8s ease-in-out, visibility 0.8s ease-in-out;

    /* Garante que apenas o slide visível receba eventos de clique */
    pointer-events: ${(props) => (props.isVisible ? "auto" : "none")};
`
export const SliderTextsContainer = styled.div<{type?: boolean}>`
    width: 400px;
    height: auto;
    margin-right: 120px;
    backdrop-filter: blur(2px);
    padding: 12px;
    box-sizing: border-box;
    border-radius: 8px;
    position: absolute;
    top: 25%;
    right: 0px;


`
export const Span = styled.span`
  font-size: 52px;
  font-weight: 600;
  border-bottom: 1px solid white;

  @media (max-width: 1200px) {
    font-size: 44px;
  }
`
export const Button = styled.button<{color?: boolean}>`
    outline: none;
    border: none;
    width: 150px;
    height: 36px;
    border-radius: 2px;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    color: white;
    transition: 0.8s;
    cursor: pointer;

    background: rgba(232, 31, 31, 0.87);
    background-size: 400% 100%;
    animation: gradient 4.3s linear infinite alternate;

    &:hover{
      background: linear-gradient(270deg, rgba(78, 2, 2, 0.68) 0%, rgba(232, 31, 31, 0.87) 60%);
    }
    
    @keyframes gradient {
        0% {
            background-position-x: 0%;
            background-position-y: 0%;
        } 
        100% {
            background-position-x: 100%;
            background-position-y: 100%;
        }
    }
`

export const ButtonNext = styled.div<{ position?: boolean }>`
  z-index: 6;
  position: absolute;
  top: 50%;
  right: 30px;
  width: auto;
  height: auto;

  @media (max-width: 1000px) {
    right: 0px;
  }

  .arrow {
    width: 30px;
    height: 40px;
    cursor: pointer;
    transform: translate(-50%, -50%);
    transition: transform 0.1s;
  }

  .arrow-top,
  .arrow-bottom {
    background-color: #666;
    height: 4px;
    position: absolute;
    top: 50%;
    left: -5px;
    width: 100%;
  }

  .arrow-top:after,
  .arrow-bottom:after {
    background-color: #fff;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.15s;
  }

  .arrow-top {
    transform: rotate(45deg);
    transform-origin: bottom right;
  }

  .arrow-top:after {
    left: 100%;
    right: 0;
    transition-delay: 0s;
  }

  .arrow-bottom {
    transform: rotate(-45deg);
    transform-origin: top right;
  }

  .arrow-bottom:after {
    left: 0;
    right: 100%;
    transition-delay: 0.15s;
  }

  .arrow:hover .arrow-top:after {
    left: 0;
    transition-delay: 0.15s;
  }

  .arrow:hover .arrow-bottom:after {
    right: 0;
    transition-delay: 0s;
  }

  .arrow:active {
    transform: translate(-50%, -50%) scale(0.9);
  }
`;

export const ButtonPrevious = styled.div`
    position: absolute;
    top: 50%;
    left: 40px;
    z-index: 6;

    @media (max-width: 1000px) {
        left: 30px;
    }
    

  .arrow {
    width: 30px;
    height: 40px;
    cursor: pointer;
    transform: translate(-50%, -50%) rotate(180deg);
    transition: transform 0.1s;
  }

  .arrow-top,
  .arrow-bottom {
    background-color: #666;
    height: 4px;
    left: -5px;
    position: absolute;
    top: 50%;
    width: 100%;
  }

  .arrow-top:after,
  .arrow-bottom:after {
    background-color: #fff;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.15s;
  }

  .arrow-top {
    transform: rotate(45deg);
    transform-origin: bottom right;
  }

  .arrow-top:after {
    left: 100%;
    right: 0;
    transition-delay: 0s;
  }

  .arrow-bottom {
    transform: rotate(-45deg);
    transform-origin: top right;
  }

  .arrow-bottom:after {
    left: 0;
    right: 100%;
    transition-delay: 0.15s;
  }

  .arrow:hover .arrow-top:after {
    left: 0;
    transition-delay: 0.15s;
  }

  .arrow:hover .arrow-bottom:after {
    right: 0;
    transition-delay: 0s;
  }

  .arrow:active {
    transform: translateX(-50%) translateY(-50%) scale(0.9) rotate(180deg); /* Inclui rotação no estado ativo */
  }
`;

export const Loader = styled.div`

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);

  @-webkit-keyframes honeycomb {
    0%,
    20%,
    80%,
    100% {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    30%,
    70% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes honeycomb {
    0%,
    20%,
    80%,
    100% {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    30%,
    70% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .honeycomb {
    height: 24px;
    position: relative;
    width: 24px;
  }

  .honeycomb div {
    -webkit-animation: honeycomb 2.1s infinite backwards;
    animation: honeycomb 2.1s infinite backwards;
    background: #f3f3f3;
    height: 12px;
    margin-top: 6px;
    position: absolute;
    width: 24px;
  }

  .honeycomb div:after, .honeycomb div:before {
    content: '';
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    position: absolute;
    left: 0;
    right: 0;
  }

  .honeycomb div:after {
    top: -6px;
    border-bottom: 6px solid #f3f3f3;
  }

  .honeycomb div:before {
    bottom: -6px;
    border-top: 6px solid #f3f3f3;
  }

  .honeycomb div:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    left: -28px;
    top: 0;
  }

  .honeycomb div:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    left: -14px;
    top: 22px;
  }

  .honeycomb div:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    left: 14px;
    top: 22px;
  }

  .honeycomb div:nth-child(4) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    left: 28px;
    top: 0;
  }

  .honeycomb div:nth-child(5) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    left: 14px;
    top: -22px;
  }

  .honeycomb div:nth-child(6) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    left: -14px;
    top: -22px;
  }

  .honeycomb div:nth-child(7) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
    left: 0;
    top: 0;
  }`;










export const BtnImage = styled.img`
    width: 60%;
    height: 60%;
`

export const ImageContainer = styled.div`
    width: 60%;
    height: auto;
    display: flex;
    justify-content: center;
    position: relative;
`

export const BannerImage = styled.img`
    width: 90%;
    height: 90%;
`
export const StyledWrapper = styled.div`
    .card_2_Container{
        width: 400px;
        height: auto;
        margin-right: 120px;
        backdrop-filter: blur(2px);
        padding:12px;
        box-sizing: border-box;
        border-radius: 8px;
        position: absolute;
        top: 25%;
        right: 0px;
    }
    .card_2{
        width: 400px;
        height: 100%;
        position: relative;
        padding-right: 12px;
        box-sizing: border-box;
        transition: all 0.75s ease;

        &:hover{
            transform: translateY(-30px);
        }
    }
    
  .card {
    width: 400px;
    height: auto;
    margin-left: 120px;
    position: relative;
  }

    .card .content,
    .card_2 .content {
    width: 100%;
    }

    .card .logo,
    .card_2 .logo {
        font-size: 44px;
        margin-bottom: 16px;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-style: normal;
        color: white;
    }

    .card .hover_content,
    .card_2 .hover_content {
        overflow: hidden;
        max-height: 0;
        transition: all 0.75s ease;
    }

    .card .hover_content p {
        font-size: 19px;
        font-weight: 450;
        font-family: "Lato", sans-serif;
        font-style: normal;
        text-align: justify;
        color: #b2b2b3;
        line-height: 1.3em;
    }

    .card_2 .hover_content p {
        padding-right: 12px;
        box-sizing: border-box;
        font-size: 19px;
        font-weight: 450;
        font-family: "Lato", sans-serif;
        font-style: normal;
        text-align: justify;
        color: #b2b2b3;
        line-height: 1.3em;
    }

    .card:hover,
    .card_2:hover {
        width: 400px;
    }

    .card:hover .hover_content{
        max-height: 15em;
        transform: none;
    }
    
    .card_2:hover .hover_content{
        max-height: 15em;
        transform: none;
        
    }
`;

export const NextButton = styled.button<{visibility?: boolean}>`
    position: absolute;
    top: 50%;
    left: 94%;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    visibility: ${({visibility})=> visibility? 'visible' : 'visible'};
    z-index: 4;
    margin: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 4;
`
export const PreviousButton = styled.button<{visibility?: boolean}>`
    position: absolute;
    top: 50%;
    right: 94%;
    width: 66px;
    height: 66px;
    visibility: ${({visibility})=> visibility? 'visible' : 'visible'};
    margin: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transform: rotate(180deg);
    z-index: 4;
`

