import styled from "styled-components";
import section_3_image from "../../assets/images/mentoria_raiz_1.png"


export const ContainerAll = styled.div`
    width: 100vw;
    height: calc(100vh - 60px);
    background: url('${section_3_image}');
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: bottom right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (min-width: 1669px) {
      background-size: contain;
    }
    @media (max-width: 1400px) {
      background: none;
      height: auto;
    }
  
`

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    @media (max-width: 1400px) {
      height: auto;
      flex-direction: column;
    }
`
export const  TextContainer = styled.div`
  height: auto;
  width: 100vw;
  padding: 40px;
  box-sizing: border-box;
  display: none;
  flex-direction: column;
  
  @media (max-width: 1400px) {
      
    display: flex;
  }
`
export const BackgroundImage = styled.div`
  width: 100vw;
  min-height: 50vh; // Altura mínima
  height: 70vh;
  position: relative;
  display: none;

  @media (max-width: 1400px) {
    display: flex;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;


export const SuportTitle = styled.div`
  width: 100%;
  font-size: 38px;
  margin-bottom: 40px;
  visibility: hidden;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: white;

  @media (max-width: 791px) {
    width: 40%;
    font-size: 28px;
  }
`
export const TextSmall = styled.div`
  margin-bottom: 16px;
  visibility: hidden;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  text-align: justify;
  line-height: 1.4em;
  color: #e6e6e6;
  position: relative;

  @media (max-width: 791px) {
    font-size: 14px;
  }
  @media (max-width: 450px) {
    font-size: 13px;
  }
`

export const StyledWrapper = styled.div`

  @media (max-width: 1400px) {
    
    display: none;
  }
  .container {
    height: auto;
    display: flex;
    align-items: center;
  }

  .card {
    position: relative;
    display: flex;
    width: 100vw;
    height: auto;
    transition: all 0.35s ease;
  }


  .card .content {
    width: 100vw;
    height: auto;
    padding: 80px 120px 120px 120px;
    box-sizing: border-box;
    display: flex;
    
  }

  .card .title-text-content{
    width: 30vw;
    display: flex;
    flex-direction: column;

    @media (max-width: 1400px) {
      width: 100%;
    }
  }

  .card .title {
    width: 100%;
    font-size: 38px;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 2px;
    color: white;
    visibility: hidden;

    @media (max-width: 791px) {
      width: 40%;
      font-size: 28px;
    }
  }

  .card .text-content {
    height: auto;
    width: 100%;
    transform: translateY(1em);
    transition: all 0.55s ease;
  }

  .card .text-content p {
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    text-align: justify;
    text-shadow: 10px 5px 10px rgb(0, 0, 0);
    line-height: 1.4em;
    color: #e6e6e6;
    position: relative;
    visibility: hidden;

    @media (max-width: 791px) {
       font-size: 14px;
    }
    @media (max-width: 450px) {
       font-size: 13px;
    }
  }
  
  .p-icon-container{
    width: 23px;
    height: 23px;
    position: absolute;
    top: 0;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p-icon-image{
    width: 100%;
    height: 100%;
  }

  .card.revealed {
    width: 100vw;
  }

  

  .card.revealed .logo {
    margin-bottom: 0.5em;
  }

  .card.revealed .text_content {
    max-height: 10em;
    transform: none;
  }`;


export const Degrade = styled.div<{phone?: boolean}>`
    position: absolute;
    left: 0;
    top: -2px;
    width: 100%;
    height: ${({phone}) => phone? '8vh' : '32vh'};
    background-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(58, 58, 58, 0));
    z-index: 0;

    @media (max-width: 1400px) {
      height: ${({ phone }) => (phone ? '12vh' : '32vh')};
    }
`
export const Degrade_2 = styled.div<{phone?: boolean}>`
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: ${({phone}) => phone? '8vh' : '32vh'};
    background-image: linear-gradient(to top, rgba(0,0,0,1), rgba(58, 58, 58, 0));
    z-index: 0;

    @media (max-width: 1400px) {
      height: ${({ phone }) => (phone ? '12vh' : '32vh')};
    }
`