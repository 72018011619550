import { Container, ListElement, ListElementImage, ListElementImageContainer, LogoContainer, LogoImage, Menu, NavContainer, NavContainerMenu, TooltipContent, UnorderedList, UnorderedList_2 } from "./headerStyled";
import logo from '../../assets/images/logo_3.png'
import perfil_image from '../../assets/images/perfil_image_2.png'
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function Header() {

    const navigate = useNavigate();
    const [tooltip, setTooltip] = useState(false)

    return (
        <Container id="header">
            <LogoContainer>
                <LogoImage src={logo} onClick={()=> navigate('/#section-1')}/>
            </LogoContainer>

            <NavContainer>
                <UnorderedList>
                    <ListElement onClick={()=> navigate('/#section-1')}>Home</ListElement>
                    <ListElement onClick={()=> navigate('/#quemsomos')}>Quem somos</ListElement>
                    <ListElement onClick={() => {
                        const targetElement = document.getElementById("suporte"); 
                        const headerHeight = document.querySelector("header")?.offsetHeight || 100; 

                        if (targetElement) {
                        // Calcula a posição absoluta do elemento
                        const rect = targetElement.getBoundingClientRect();
                        const absoluteTop = rect.top + window.scrollY;

                        window.scrollTo({
                            top: absoluteTop - headerHeight, // Ajusta pelo header
                            behavior: "smooth", // Scroll suave
                        });
                        } else {
                            console.warn("Elemento com ID 'suporte' não encontrado.");
                        }
                    }}>Serviços</ListElement>
                    <ListElement onClick={() => {
                        const targetElement = document.getElementById("produtos"); 
                        const headerHeight = document.querySelector("header")?.offsetHeight || 100; 

                        if (targetElement) {
                        // Calcula a posição absoluta do elemento
                        const rect = targetElement.getBoundingClientRect();
                        const absoluteTop = rect.top + window.scrollY;

                        window.scrollTo({
                            top: absoluteTop - headerHeight, // Ajusta pelo header
                            behavior: "smooth", // Scroll suave
                        });
                        } else {
                            console.warn("Elemento com ID 'suporte' não encontrado.");
                        }
                    }}>Produtos</ListElement>
                    <ListElement onClick={()=> navigate('/#depoimentos')}>Depoimentos</ListElement>
                    <ListElement onClick={() => {
                        const targetElement = document.getElementById("contato"); 
                        const headerHeight = document.querySelector("header")?.offsetHeight || 100; 

                        if (targetElement) {
                        // Calcula a posição absoluta do elemento
                        const rect = targetElement.getBoundingClientRect();
                        const absoluteTop = rect.top + window.scrollY;

                        window.scrollTo({
                            top: absoluteTop - headerHeight, // Ajusta pelo header
                            behavior: "smooth", // Scroll suave
                        });
                        } else {
                            console.warn("Elemento com ID 'suporte' não encontrado.");
                        }
                    }}>Contato</ListElement>
                    <ListElement onClick={() => window.location.href = 'http://helpdesk.dubrasilsolucoes.com.br/HelpDesk/Login.xhtml'}>
                        <ListElementImageContainer>
                            <ListElementImage src={perfil_image}/>
                        </ListElementImageContainer>
                        Abrir chamado
                    </ListElement>
                </UnorderedList>
            </NavContainer>

            <Menu isActive={tooltip}>
                <div className="nav_bar" onClick={()=> setTooltip(!tooltip)}>
                    <div className="bar1" />
                    <div className="bar2" />
                    <div className="bar3_h" />
                    <div className="bar4" />
                </div>
                <TooltipContent className="tooltip-content" isActive={tooltip}>
                    <NavContainerMenu>
                        <UnorderedList_2>
                            <ListElement onClick={()=> navigate('/#section-1')}>Home</ListElement>
                            <ListElement onClick={()=> navigate('/#quemsomos')}>Quem somos</ListElement>
                            <ListElement onClick={()=> navigate('/#suporte')}>Serviços</ListElement>
                            <ListElement onClick={()=> navigate('/#produtos')}>Produtos</ListElement>
                            <ListElement onClick={()=> navigate('/#depoimentos')}>Depoimentos</ListElement>
                            <ListElement onClick={()=> navigate('/#contato')}>Contato</ListElement>
                            <ListElement onClick={() => window.location.href = 'http://helpdesk.dubrasilsolucoes.com.br/HelpDesk/Login.xhtml'}>
                                <ListElementImageContainer>
                                    <ListElementImage src={perfil_image}/>
                                </ListElementImageContainer>
                                Abrir chamado
                            </ListElement>
                        </UnorderedList_2>
                    </NavContainerMenu>
                </TooltipContent>
            </Menu>
        </Container>
    );
}

export default Header;