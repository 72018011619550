import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './home/Home';
import Section3 from './components/section_suporte_humanizado/Suporte';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
