import { useEffect } from "react";
import { BackgroundImage, Container, ContainerAll, Degrade, Degrade_2, Image, StyledWrapper, SuportTitle, TextContainer, TextSmall } from "./mentoria_styled";
import ScrollReveal from "scrollreveal";
import implantacao_img from "../../assets/images/implantacao_raiz_2.webp"
import lowQualityImage from "../../assets/images/mentoria_especializada_3_2.png"
import check from '../../assets/images/iconscheck1.png'

function Mentoria() {

    useEffect(() => {
        ScrollReveal().reveal(".card", {
          reset: false,
          afterReveal: (el) => {
            if (el instanceof HTMLElement) {
              el.classList.add("revealed"); // Garantimos que 'el' é um HTMLElement
            }
          },
        });

        ScrollReveal().reveal(".title", {
            duration: 2000, // Duração da animação
            origin: "bottom", // Origem da animação
            distance: "70px", // Distância do movimento
            reset: false, // Não reinicia a animação ao rolar novamente    
          });

        ScrollReveal().reveal(".paragraph", {
            duration: 2800, // Duração da animação
            origin: "right", // Origem da animação
            distance: "60px", // Distância do movimento
            reset: false, // Não reinicia a animação ao rolar novamente    
          });
        
      }, []);

    return(
        <ContainerAll>
            <Degrade/>
            <Degrade_2/>
            <Container>
                <TextContainer>
                    <SuportTitle id="suporte" className="title">MENTORIA EM DEPARTAMENTO PESSOAL</SuportTitle>

                    <TextSmall className="paragraph">
                        Nossa mentoria vai além de resolver problemas pontuais. Oferecemos um serviço estratégico
                        para ajudar sua empresa a implantar e utilizar sistemas de ponto de forma assertiva, 
                        reduzindo erros e economizando tempo valioso.
                    </TextSmall>
                    <TextSmall className="paragraph">
                        Com nossa expertise de mais de 25 anos e profundo conhecimento em gestão de ponto, 
                        orientamos desde a configuração inicial até a implementação completa, 
                        garantindo que sua empresa esteja alinhada com as melhores práticas e a legislação vigente.
                    </TextSmall>
                    <TextSmall className="paragraph">
                        Economize tempo, minimize problemas e maximize resultados com nossa mentoria especializada!
                    </TextSmall>
                </TextContainer>

                <BackgroundImage>
                    <Image 
                    src={implantacao_img}
                    alt="Background" 
                    loading="lazy" 
                    />
                    <Degrade phone />
                    <Degrade_2 phone />
                </BackgroundImage>

                <StyledWrapper>
                    <div className="card">
                        <div className="content">
                            <div className="title-text-content">
                                <div className="title" id="mentoria">MENTORIA EM DEPARTAMENTO PESSOAL</div>
                                <div className="text-content">
                                <p className="paragraph">
                                    <div className="p-icon-container">

                                    </div>
                                    Nossa mentoria vai além de resolver problemas pontuais. Oferecemos um serviço estratégico
                                    para ajudar sua empresa a implantar e utilizar sistemas de ponto de forma assertiva, 
                                    reduzindo erros e economizando tempo valioso. 
                                </p>
                                <p className="paragraph">
                                    <div className="p-icon-container">

                                    </div>
                                    Com nossa expertise de mais de 25 anos e profundo conhecimento em gestão de ponto, 
                                    orientamos desde a configuração inicial até a implementação completa, 
                                    garantindo que sua empresa esteja alinhada com as melhores práticas e a legislação vigente.  
                                </p>
                                <p className="paragraph">
                                    <div className="p-icon-container">

                                    </div>
                                    Economize tempo, minimize problemas e maximize resultados com nossa mentoria especializada! 
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>       
                </StyledWrapper>
            </Container>
        </ContainerAll>
    )
}

export default Mentoria;