import styled, { keyframes }from "styled-components";
import image from "../../assets/images/header_image.png"

export const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 60px;
    padding: 0px 22% 0px 22%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url(${image});
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgb(0, 0, 0, 0.15) 0px 10px 10px;
    z-index: 5;
    position: fixed;
    
    @media (max-width: 1500px) {
        padding: 0px 0px;
        background: linear-gradient(to bottom right, #282837 0%, #101012 47%);
    }
`

export const LogoContainer = styled.div`
    width: auto;
    height: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media (max-width: 1500px) {
        margin-left: 16px;
        
    }
`
export const LogoImage = styled.img`
    width: 100%;
    height: 100%;
    max-width: 40vw;
`

export const NavContainer = styled.nav`
    width: auto;
    height: 50px;
    display: flex;
    align-items: center;

    @media (max-width: 1500px) {
        margin-right: 32px;
        
    }

    @media (max-width: 950px) {
        display: none;
    }
`
export const NavContainerMenu = styled.nav`
    width: auto;
    height: auto;
`

export const UnorderedList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: auto;
    display: flex;
    align-items: center;
    column-gap: 18px;
`
export const UnorderedList_2 = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
`
export const ListElement = styled.li`
    width: auto;
    height: auto;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
    color: white;
    transition: 0.3s;
    cursor: pointer;
    position: relative; /* Necessário para o posicionamento dos pseudo-elementos */
    display: flex;

    &::before,
    &::after {
        content: '';
        width: 0%;
        height: 2px;
        background: linear-gradient(to right, #a6a6a6 0%, #ffffff 100%);
        display: block;
        position: absolute;
        bottom: 0; /* Alinha as barras ao final do botão */
        transition: width 0.5s;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }

    &:hover::after,
    &:hover::before {
        width: 100%;
  }
`
export const ListElementImageContainer = styled.div`
    width: 22px;
    height: 22px;
    margin-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ListElementImage = styled.img`
    width: 100%;
    height: 100%;
`


export const Menu = styled.div<{ isActive: boolean }>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    width: 100%;


    @media (max-width: 950px) {
        display: flex;
        flex-direction: row-reverse;
    }

  .nav_bar {
    background-color: transparent;
    position: relative;
    display: flex;
    transition: 0.4s;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 18px 8px;
    box-sizing: border-box;
    border-radius: ${(props) => (props.isActive ? "50px" : "8px")};
    margin-right: 32px;
  }

  .bar1,
  .bar2,
  .bar3_h,
  .bar4 {
    border-radius: 30px;
    background-color: rgb(255, 255, 255);
    width: 30px;
    height: 2px;
    transition: 0.4s;
  }

  .bar1 {
    margin-bottom: 8px;
    opacity: ${(props) => (props.isActive ? "0" : "1")};
  }

  .bar4 {
    margin-top: 8px;
    opacity: ${(props) => (props.isActive ? "0" : "1")};
  }

  .bar2 {
    position: absolute;
    transform: ${(props) => (props.isActive ? "rotate(45deg)" : "none")};
  }

  .bar3_h {
    transform: ${(props) => (props.isActive ? "rotate(-45deg)" : "none")};
  }

`


export const TooltipContent = styled.div<{ isActive: boolean }>`
    width: 100%; /* Ocupa 100% da largura do elemento pai */
    height: auto;
    padding: 24px 0px;
    box-sizing: border-box;
    position: absolute;
    left: 0; /* Alinhar ao início do pai */
    top: 100%; /* Posiciona abaixo do elemento pai */
    opacity: ${({ isActive }) => (isActive ? "1" : "0")};
    transform: ${({ isActive }) =>
        isActive ? "translateY(0)" : "translateY(-20px)"};
    transition: opacity 0.6s ease, transform 0.6s ease; /* Transições suaves */
    background: linear-gradient(to top left, #282837 0%, #101012 55%);
    display: flex; /* Sempre renderiza, mas controla visibilidade */
    justify-content: center;
    align-items: center;
    pointer-events: ${({ isActive }) => (isActive ? "auto" : "none")}; /* Evita cliques quando invisível */
`;

