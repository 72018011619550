import styled, { keyframes } from "styled-components";

export const StyledWrapper = styled.div<{rep?: boolean}>`
  @media (max-width: 1300px) {
      display: flex;
      justify-content: center;
    }
.card {
    width: auto;
    min-width: 200px;
    height: auto;
    padding: 0.5rem;
    background: linear-gradient(144deg, rgba(119, 119, 185, 0.5) 0%, rgba(16,16,18,0.3) 70%);
    border-radius: 8px;
    transition: .4s;
    overflow: hidden;
    color: white;

    @media (max-width: 1300px) {
      width: 80%;
    }
    @media (max-width: 600px) {
      width: auto;
    }
  }
  .card-invertido {
    width: auto;
    min-width: 200px;
    height: auto;
    padding: 0.5rem;
    background: linear-gradient(244deg, rgba(119, 119, 185, 0.5) 0%, rgba(16,16,18,0.3) 70%);
    border-radius: 8px;
    transition: .4s;
    overflow: hidden;
    color: white;

    @media (max-width: 1300px) {
      width: 80%;
    }
    @media (max-width: 600px) {
      width: auto;
    }
  }
  
  .align {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-self: flex-start;
  }

  .align-invertido {
    padding: 1rem;
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;

  }

  .red {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff605c;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.280);
  }

  .yellow {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffbd44;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.280);
  }

  .green {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #00ca4e;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.280);
  }

  .card h1, .card-invertido h1 {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 48px;
  padding: 0px 0px 18px 0px;
  box-sizing: border-box;
  color: white;
  text-shadow: -10px 5px 10px rgba(63, 61, 61, 0.59);
  position: relative;

  @media (max-width: 791px) {
      font-size: 28px;
    }
}

.card p, .card-invertido p {
  text-align: justify;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: 400;
  padding: 12px;
  color: white;

  @media (max-width: 791px) {
       font-size: 18px;
  }
  @media (max-width: 450px) {
      font-size: 14px;
  }
}
`
const shine = keyframes`
  0% {
    background-position: 0;
  }
  60% {
    background-position: 1200px;
  }
  100% {
    background-position: 2400px;
  }
`;

// Estilização do texto
export const MiniText = styled.div`
  width: 80%;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 15px;
  font-family: "Lato", sans-serif;
  color: #36364a;
  background: linear-gradient(to right, #9f9f9f 0, #fff 10%, #868686 20%);
  background-position: 0;
  background-clip: text; /* Standard syntax */
  -webkit-background-clip: text; /* Webkit compatibility */
  -webkit-text-fill-color: transparent;
  animation: ${shine} 12s infinite linear;
  animation-fill-mode: forwards;
  font-weight: 400;
  text-decoration: none;

  @media (max-width: 791px) {
       font-size: 12px;
  }

  @media (max-width: 700px) {
      width: 80%;
      white-space: break-spaces;
      transform: translate(-50%, 50%)
  }
`;


export const Button = styled.div<{ red?: boolean }>`
  .btn {
    width: 150px;
    height: 36px;
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
    z-index: 6;
    background-color: ${({ red }) =>
    red
      ? 'rgba(232, 31, 31, 0.87)'
      : 'rgb(14, 14, 26)'};
    color: rgb(234, 234, 234);
    font-weight: 700;
    font-family: 'Lato';
    transition: 0.6s;
  }

  .btn:active {
    scale: 0.92;
  }

  .btn:hover {
    background: ${({ red }) =>
    red
      ? 'linear-gradient(270deg, rgba(78, 2, 2, 0.68) 0%, rgba(232, 31, 31, 0.87) 60%)'
      : 'linear-gradient(270deg, rgba(2, 29, 78, 0.681) 0%, rgba(31, 215, 232, 0.873) 60%)'};
    color: ${({ red }) =>
    red
      ? 'rgb(196, 188, 188)'
      : 'rgb(4, 4, 38)'};
  }
`;


