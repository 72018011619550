import React, { useState, useEffect, useRef } from "react";
import { SliderShow, VideoBackground, SlideContent, Section_1, Degrade, Span, Button, SlideTexts, SlideText, ButtonNext, ButtonPrevious, Loader } from "./section_1_styled";

const isMobile = window.innerWidth < 768;
const videos = isMobile
  ? [
      "/videos/video1_mobile_2.mp4",
      "/videos/video2_mobile_2.mp4",
      "/videos/video3_mobile.mp4",
    ]
  : [
      "/videos/video1.mp4",
      "/videos/video2.mp4",
      "/videos/video3.mp4",
    ];

const SlideShow = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const slideshowRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLoading, setIsLoading] = useState(true);
  const isIPhone = /iPhone|iPad|iPod/i.test(navigator.userAgent);


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Ajuste a sensibilidade
    );

    if (slideshowRef.current) {
      observer.observe(slideshowRef.current);
    }

    return () => {
      if (slideshowRef.current) {
        observer.unobserve(slideshowRef.current);
      }
    };
  }, []);

  /*Configuração para dispositivos IOS
  useEffect(() => {
    if (isIPhone) {
      const videoElement = document.querySelector("video");
      if (videoElement) {
        videoElement.play().catch((error) => {
          console.warn("Autoplay bloqueado no iPhone:", error);
        });
      }
    }
  }, [currentSlideIndex]);
  */

  useEffect(() => {
    if (!isVisible) {
      // Pause os vídeos quando o SlideShow não está visível
      const video = document.querySelector("video");
      if (video) video.pause();
    }
  }, [isVisible]);


  const NextSlide = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setIsLoading(true); // Ativa o loader antes da troca
  
    setTimeout(() => {
      setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % videos.length);
      setIsTransitioning(false);
    }, 300);
  };
  
  const PreviousSlide = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setIsLoading(true); // Ativa o loader antes da troca
  
    setTimeout(() => {
      setCurrentSlideIndex((prevIndex) =>
        prevIndex === 0 ? videos.length - 1 : prevIndex - 1
      );
      setIsTransitioning(false);
    }, 300);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      NextSlide();
    }, 8000);

    return () => clearInterval(interval);
  }, [currentSlideIndex]); // Reinicia o intervalo ao mudar o slide

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Section_1 id="section-1" ref={slideshowRef}>
        <SliderShow>
        <Degrade/>

            <ButtonNext onClick={()=> NextSlide()}>
                <div className="arrow">
                    <div className="arrow-top" />
                    <div className="arrow-bottom" />
                </div>
            </ButtonNext>

            <ButtonPrevious onClick={()=> PreviousSlide()}>
                <div className="arrow">
                    <div className="arrow-top" />
                    <div className="arrow-bottom" />
                </div>
            </ButtonPrevious>

            {isLoading && (
              <Loader>
                <div className="honeycomb">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </Loader>
            )}

            <VideoBackground
                key={currentSlideIndex}
                playsInline
                autoPlay
                controls={false}
                muted
                loop
                src={videos[currentSlideIndex]}
                isTransitioning={isTransitioning}
                preload={isIPhone ? "metadata" : "auto"} // Ajusta conforme o dispositivo
                onLoadedData={() => setIsLoading(false)} // Remove o loader quando o vídeo estiver pronto
                onWaiting={() => setIsLoading(true)} // Se o vídeo travar, ativa o loader de novo
                onPlaying={() => setIsLoading(false)} // Quando começar a tocar, remove o loader

            />
            <SlideContent>
                {currentSlideIndex === 0 && (
                <SlideTexts>
                    <SlideText>
                      Transformando o registro de ponto com simplicidade e máxima segurança
                    </SlideText>
                    <Button onClick={() => {
                      const targetElement = document.getElementById("mentoria"); 
                      const headerHeight = document.querySelector("header")?.offsetHeight || 100; 

                      if (targetElement) {
                      // Calcula a posição absoluta do elemento
                      const rect = targetElement.getBoundingClientRect();
                      const absoluteTop = rect.top + window.scrollY;

                      window.scrollTo({
                          top: absoluteTop - headerHeight, // Ajusta pelo header
                          behavior: "smooth", // Scroll suave
                      });
                      } else {
                      console.warn("Elemento com ID 'suporte' não encontrado.");
                      }
                  }}>Saiba mais</Button>
                </SlideTexts>
                )}
                {currentSlideIndex === 1 && (
                <SlideTexts>
                    <SlideText>
                        Aqui humanos são atendidos por <Span>Humanos</Span>
                    </SlideText>
                    <Button  onClick={() => {
                      const targetElement = document.getElementById("suporte"); 
                      const headerHeight = document.querySelector("header")?.offsetHeight || 100; 

                      if (targetElement) {
                      // Calcula a posição absoluta do elemento
                      const rect = targetElement.getBoundingClientRect();
                      const absoluteTop = rect.top + window.scrollY;

                      window.scrollTo({
                          top: absoluteTop - headerHeight, // Ajusta pelo header
                          behavior: "smooth", // Scroll suave
                      });
                      } else {
                      console.warn("Elemento com ID 'suporte' não encontrado.");
                      }
                  }}>Saiba mais</Button>
                </SlideTexts>
                )}
                {currentSlideIndex === 2 && (
                <SlideTexts>
                    <SlideText>
                        <Span>Registramos</Span> o ponto do seu colaborador onde você nem imagina
                    </SlideText>
                    <Button  onClick={() => {
                      const targetElement = document.getElementById("produtos"); 
                      const headerHeight = document.querySelector("header")?.offsetHeight || 100; 

                      if (targetElement) {
                      // Calcula a posição absoluta do elemento
                      const rect = targetElement.getBoundingClientRect();
                      const absoluteTop = rect.top + window.scrollY;

                      window.scrollTo({
                          top: absoluteTop - headerHeight, // Ajusta pelo header
                          behavior: "smooth", // Scroll suave
                      });
                      } else {
                      console.warn("Elemento com ID 'suporte' não encontrado.");
                      }
                  }}>Saiba mais</Button>
                </SlideTexts>
                )}
            </SlideContent>
        </SliderShow>
    </Section_1>
  );
};

export default SlideShow;
