import { Button, MiniText, StyledWrapper } from "./card_produtos_Styled";

interface CardProps {
    title: string,
    text: string,
    invertido?: boolean,
    minitext?: string,
    rep?: boolean,
}

function Card3D({title, text, minitext, invertido, rep}: CardProps) {
    return (
        <StyledWrapper rep={rep}>
            <div className={invertido? 'card-invertido' : 'card'}>
                <div className={invertido? 'align-invertido' : 'align'}>
                    <span className="red" />
                    <span className="yellow" />
                    <span className="green" />
                </div>
                <h1>
                    {title}
                    <MiniText>{minitext}</MiniText>
                </h1>
                <p>{text}</p>
                
            </div>
        </StyledWrapper>
    )
}

export default Card3D;